import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { applicantsTypes } from "../../ActionTypes/applicantsTypes";
import { fetchApplicantsFailure, fetchApplicantsSuccess } from "../../actions/applicantsActions/applicantsActions";


var getApplicants = (id:any, types: any, a: any) => {
  if(types == 'get') {
    return axios.get(API_BASE_ADDRESS + '/applicants/' + id, {headers: {
      Authorization: localStorage.getItem('jobParachannelAuthToken') ? localStorage.getItem('jobParachannelAuthToken') : localStorage.getItem('jobParachannelAuthAdminToken'),
      Accept: '*/*',
    }}); 
  } else if(types == 'put') {
    return axios.put(API_BASE_ADDRESS + '/applicants/' + id, {is_active: a}, {headers: { Accept: 'application/json'}}); 
  } else {
    return axios.get(API_BASE_ADDRESS + '/applicants/' + id + '/company', {headers: { Accept: 'application/json', Authorization: localStorage.getItem('jobParachannelAuthToken')}}); 
  }
}

function* fetchApplicantsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getApplicants, action.id, action.types, action.a);
    yield put(
      fetchApplicantsSuccess({
        applicants: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchApplicantsFailure({
        error: e.message
      })
    );
  }
}

function* ApplicantsSaga() {
  yield takeLatest(applicantsTypes.FETCH_APPLICANTS_REQUEST, fetchApplicantsSaga);
}

export default ApplicantsSaga;

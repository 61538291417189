import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminCompanyDetailFailure, fetchAdminCompanyDetailSuccess } from "../../actions/adminCompanyDetailActions/adminCompanyDetailActions";
import { adminCompanyDetailTypes } from "../../ActionTypes/adminCompanyDetailTypes";

const getAdminCompanyDetail = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/companies/' + data.company_id, config); 
}


function* fetchAdminCompanyDetailSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminCompanyDetail, action.data);
    yield put(
      fetchAdminCompanyDetailSuccess({
        company: response.data.company,
        purpose_of_postings: response.data.purpose_of_postings,
        recruiters: response.data.recruiters,
        recruitments: response.data.recruitments,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminCompanyDetailFailure({
        error: e.message
      })
    );
  }
}

function* AdminCompanyDetailSaga() {
  yield takeLatest(adminCompanyDetailTypes.FETCH_ADMIN_COMPANY_DETAIL_REQUEST, fetchAdminCompanyDetailSaga);
}
export default AdminCompanyDetailSaga;
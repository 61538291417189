import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redax/reducers/rootReducer';
import { fetchResetRequest } from '../../redax/actions/resetActions/resetActions';
import { useNavigate } from 'react-router-dom';

function RecruiterPasswordReset() {
  const [screen, setScreen] = useState(0);
  const [error, setError] = useState<boolean>(false);
  const [mistake, setMistake] = useState<boolean>(false);
  const [mistake2, setMistake2] = useState<boolean>(false);
  const [match, setMatch] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordminLength, setErrorPasswordminLength] = useState(false);
  const [errorPasswordmaxLength, setErrorPasswordmaxLength] = useState(false);
  const [errorPassword2, setErrorPassword2] = useState(false);
  const [errorPasswordminLength2, setErrorPasswordminLength2] = useState(false);
  const [errorPasswordmaxLength2, setErrorPasswordmaxLength2] = useState(false);
  const [passConfirm, setPassConfirm] = useState<boolean>(false);
  const [password, setPassword] = useState<string | null>(null);
  const [newpassword, setNewPassword] = useState<string | null>(null);
  const dispatch = useDispatch();
  const reset = useSelector((state: RootState) => state.reset);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const navigate = useNavigate();  

  const onChangePassword = (value: any) => {
    setPassword(value);
    Validation(value);
  }
  
  function Validation (value: any) {
    let passwordValidate = /^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/
    // const passwordValidate = new RegExp('/^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/');
    if(value === ''){
      setErrorPassword(true);
      setMistake(false);
      setErrorPasswordminLength(false);
      setErrorPasswordmaxLength(false);
    } else {
      setErrorPassword(false);
      if(passwordValidate.test(value)){
        setMistake(false);
      } else {
        setMistake(true);
      }
      if(value.length >= 8){
        setErrorPasswordminLength(false)
      }else{
        setErrorPasswordminLength(true)
      }
      if(value.length <= 32){
        setErrorPasswordmaxLength(false)
      }else{
        setErrorPasswordmaxLength(true)
      }
    }
  }

  const passwordNewChange = (value: any) => {
    setNewPassword(value);
    DataValidation(value);
  }
  function DataValidation (value: any) {
    let passwordValidate = /^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/
    // const passwordValidate = new RegExp('/^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$/');
    if(value === ''){
      setErrorPassword2(true);
      setMistake2(false);
      setErrorPasswordminLength2(false);
      setErrorPasswordmaxLength2(false);
    } else {
      setErrorPassword2(false);
      if(passwordValidate.test(value)){
        setMistake2(false);
      } else {
        setMistake2(true);
      }
      if(value.length >= 8){
        setErrorPasswordminLength2(false)
      }else{
        setErrorPasswordminLength2(true)
      }
      if(value.length <= 32){
        setErrorPasswordmaxLength2(false)
      }else{
        setErrorPasswordmaxLength2(true)
      }
    }
    if(password === value){
      setPassConfirm(false);
    }else{
      setPassConfirm(true);
    }
  }

  function passwordResetBtn (e: any) {
    if(password == null || password == '') {
      setErrorPassword(true);
      return;
    }
    if(newpassword == null || newpassword == '') {
      setErrorPassword2(true);
      return;
    }
    if(password !== newpassword) {
      return;
    }
    dispatch(fetchResetRequest(password, newpassword, token));
    e.preventDefault();
  }

  useEffect(() => {
    if(reset !== undefined && reset.pending) {
      setScreen(1);
      setTimeout( () => navigate('/mypage/init/'), 1500);
    } else if(reset.error !== null) {
      setScreen(2);
    }
  }, [dispatch, reset]);
  

  return (
    <main id="login">
      <section className="wrapper">
        <h2 className="ttl type3">ログイン</h2>
        <div className="input_box">
          <h3 className="ttl">パスワード再設定</h3>

          {screen === 1 ?
            <p>パスワード再設定が完了しました。<br/>自動でマイページにリダイレクトします。</p>
          : <></>}
          {screen === 2 ?<p>エラーが発生しました。<br/>恐れ入りますがもう一度やり直してください。</p> : <></>}

          {screen === 0 ?
          // <form>
          <div className='form'>
          <dl>
              <dt>新しいパスワード</dt>
              <dd>
                <input
                  type="password"
                  title="新しいパスワード"
                  name="password"
                  value={password || ''}
                  onChange={(e) => onChangePassword(e.target.value)}
                />
                {errorPassword || errorPasswordminLength || errorPasswordmaxLength || mistake ? 
                  <div>
                    {errorPassword ? 
                    <div>
                      <span>必須項目です</span>
                    </div>
                  : <></>}
                  {errorPasswordminLength || errorPasswordmaxLength ? 
                    <div>
                      <span>8文字以上32文字以下で入力してください</span>
                    </div>
                  : <></>}
                  {mistake ? 
                    <div>
                      <span>大文字と小文字を1文字以上含めた半角英数字で入力してください</span>
                    </div>
                  : <></>}
                  </div>
                  : <></>
                  }
              </dd>
              <dt>新しいパスワード<span>(確認)</span></dt>
              <dd>
                <input
                  type="password"
                  title="新しいパスワード"
                  name="newPassword"
                  value={newpassword || ''}
                  onChange={(e) => passwordNewChange(e.target.value)}
                />
                {errorPassword2 || errorPasswordminLength2 || errorPasswordmaxLength2 || mistake2 ? 
                  <div>
                    {errorPassword2 ? 
                    <div>
                      <span>必須項目です</span>
                    </div>
                  : <></>}
                  {errorPasswordminLength2 || errorPasswordmaxLength2 ? 
                    <div>
                      <span>8文字以上32文字以下で入力してください</span>
                    </div>
                  : <></>}
                  {mistake2 ? 
                    <div>
                      <span>大文字と小文字を1文字以上含めた半角英数字で入力してください</span>
                    </div>
                  : <></>}
                  </div>
                  : <></>
                  }
                {passConfirm ? 
                    <div>
                        <span>確認用パスワードが一致していません</span>
                    </div>
                : <></>}
              </dd>
            </dl>
            <button type="submit" onClick={(e) => passwordResetBtn(e)} className="btn type3">再設定</button>
          </div>
          : <></>}
        </div>
      </section>
    </main>
  );
}

export default RecruiterPasswordReset;
import { fetchLoginRequest } from '../../../../redax/actions/loginActions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, CSSProperties  } from 'react';
import MypageSideBar from '../../../../components/MypageSideBar/MypageSideBar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../../redax/reducers/rootReducer';
import { fetchScoutsCompanyRequest } from '../../../../redax/actions/scoutsCompanyActions/scoutsCompanyActions.';
import { fetchScoutAlertRequest } from '../../../../redax/actions/scoutsAlertActions/scoutsAlertActions';
import '../../index/index.scss';
import '../../../../styles/style.css';
import './id.scss';
import Loader from '../../../loader/loader';

export default function IdPage() {
    let pages;
    let all_count;
    let total_count ;
    let search_criteria = [];
    let recruitmentsLists = [];
    let page: any;
    let pagenationPage: any;
    let param;
    let param1;
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [scoutsData, setScoutsData] = useState<any>([]);
    const [scoutsCompanyData, setScoutsCompanyData] = useState<any>([]);
    const [scouts, setScouts] = useState<any>([]);
    const [scoutsRecruitment, setScoutsRecruitment] = useState<any>([]);
    const [isOpens, setIsOpens] = useState<any>([]);
    const [pagenation, setPagenation] = useState<any>([]);
    const itemsPerPage = 1;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id } = useParams();
    const loginData = useSelector((state: RootState) => state.login);  
    const scoutsCompany = useSelector((state: RootState) => state.scoutsCompany);
    const scoutAlert = useSelector((state: RootState) => state.scoutAlert);
    const [loadScreen, setLoadScreen] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);

    if(queryParams.get('page')){
        pagenationPage =  queryParams.get('page');
    } else {
        page = 1;
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [total_pages, setTotalPages] = useState(1);

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
            dispatch(fetchLoginRequest('', '',''))
            param = '/scouts/company'
            dispatch(fetchScoutsCompanyRequest(param));
          } else (
              navigate('/login/')
          )
        // dispatch(fetchLoginRequest('', '', ''));
    }, [dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/mypage/init/');
            }
            setFamylyName(loginData.login.resource.family_name);
            setResourceId(loginData.login.resource.id);
        }
    }, [loginData.pending, familyName]);

    useEffect(() => {
        if(scoutsCompany.pending && firstLoad) {
            setLoadScreen(false);
            if(scoutsCompany.scoutsCompany.company) {
                setScoutsData(scoutsCompany.scoutsCompany);
                setScoutsCompanyData(scoutsCompany.scoutsCompany.company);
                setScouts(scoutsCompany.scoutsCompany.scouts);
                let c = [];
                for(let i = 0; i < scoutsCompany.scoutsCompany.scouts.length; i++) {
                    c.push(false);
                }
                setIsOpens(c);
                // setPagenation(scoutsCompany.scoutsCompany.pagenation.total_pages);    
                setTotalPages(scoutsCompany.scoutsCompany.pagenation.total_pages);
                all_count =  scoutsCompany.scoutsCompany.pagenation.all_count;
                total_count =  scoutsCompany.scoutsCompany.pagenation.total_count;
                setFirstLoad(false);
            } else {
                setLoadScreen(true);
                param1 = '/scouts/company/' + id + '?page=' + currentPage 
                dispatch(fetchScoutsCompanyRequest(param1));
            }
        } else if(scoutsCompany.error != null) {
            setLoadScreen(false);
            // navigate('/error?code=500');
        }
    }, [scoutsCompany, firstLoad]);

    useEffect(() => {
        if(scoutAlert.pending) {
            setLoadScreen(false);
            window.location.reload();
        } else if(scoutAlert.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [scoutAlert]);

    // if(pages !== undefined) {
    const totalPages = Math.ceil(page / itemsPerPage);
    // }

    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
    }

    function scoutDate(createdAt: any) {
        const createdAtArr = createdAt.split(' ');
        return createdAtArr[0];
    }
    function scoutTime(createdAt: any) {
        const createdAtArr = createdAt.split(' ');
        return createdAtArr[1];
    }

    function numOfOtherScout(recruitments: any) {
          const numOfOtherScout = recruitments.length - 1;
          return numOfOtherScout == 0 ? '' : '　他' + numOfOtherScout + '件 ';
    }

    function companyBusinessContent() {
        if (scoutsData.company.business_content) 
            return scoutsData.company.business_content + '　'
    }

    function setActiveTabForRecruitmentsPage () {
        let param = 'recruitments/activeTab' + 1;
        return param;
    }

    function notificationAlert () {
        if(window.confirm('不適切なスカウトを管理者に報告しますか？')) {
            setLoadScreen(true);
            dispatch(fetchScoutAlertRequest(id));
        }
    }

    function toggleScout(scoutId: any, i: any) {
        let c = [];
        for(let j = 0; j < isOpens.length; j++) {
            c.push(isOpens[j]);
        }
        if(isOpens[i] == true) {
            c[i] = false;
        } else {
            c[i] = true;
        }
        setIsOpens(c);
        setFirstLoad(false);
        let prm = '/scouts/' + scoutId + '/read';
        dispatch(fetchScoutsCompanyRequest(prm));
    }

    const pageIncrement = () => {
        if (currentPage >= totalPages) {
          setCurrentPage(totalPages);
          return;
        }
        setCurrentPage(currentPage + 1);
        param1 = '/scouts/company/' + id + '?page=' + (currentPage + 1);
        dispatch(fetchScoutsCompanyRequest(param1));
        setFirstLoad(true);
      };

      const setCurrentPageLoad = (id: any) => {
        if(currentPage == id) {
            return;
        }
        setCurrentPage(id);
        param1 = '/scouts/company/' + id + '?page=' + id;
        dispatch(fetchScoutsCompanyRequest(param1));
        setFirstLoad(true);
      };

      const pageDecrement = () => {
        if (currentPage <= 1) {
          setCurrentPage(1);
          return;
        }
        setCurrentPage(currentPage - 1);
        param1 = '/scouts/company/' + id + '?page=' + (currentPage - 1);
        dispatch(fetchScoutsCompanyRequest(param1));
        setFirstLoad(true);
      };
      const pageNumbers = Array.from({ length: page }, (_, index) => index + 1);

    // const pageNumbers = Array.from({ length: page }, (_, index) => index + 1);

    function replacedContent(value: any) {
        if (value === null) {
          return null;
        }
        // return value.replaceAll("\n", '<br>');
    }

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="#">マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">あなたに興味がある企業</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div id="scouts" className="mypage company">
                <a href={'/mypage/scouts'} className="btn">一覧へ戻る</a>
                { scoutsCompanyData !== undefined ?
                    <div className="cont1">
                        <div className="img_box">
                            <img src={scoutsCompanyData.logo == '' ? '/assets/img/company/img02.jpg' : scoutsCompanyData.logo} alt={scoutsCompanyData.name}/>
                        </div>
                        <div className="text_box"> 
                            <h3>{ scoutsCompanyData.name }</h3>
                            <ul className="clip">
                                {scoutsCompanyData.recruitments && scoutsCompanyData.recruitments.map((recruitment: any) => (
                                    <li key={recruitment.id}>
                                        <a href={'/recruitments/' + recruitment.id}>{recruitment.job_title}</a>
                                    </li>
                                ))} 
                            </ul>
                        </div>
                    </div>
                :<></>}
                { scouts !== null ?
                    <div className="cont2">
                        <ul>
                            {scouts && scouts.map((scout: any, i: any) => ( 
                                <li key={scout.id} className={ !isOpens[i] ? "":"active"} onClick={(e)=>toggleScout(scout.id, i)}>
                                    <div className="type1">
                                        <p className="date">{ scoutDate(scout.created_at) }</p>
                                        <p className="time">{ scoutTime(scout.created_at) }</p>
                                        <p>{ scout.recruitments[0].job_title } { numOfOtherScout(scout.recruitments) }の スカウトが届きました</p>
                                    </div>
                                    <div className={ !isOpens[i] ? "none":"type2"}>
                                        <div>
                                            <p>{ familyName }様</p><br/>
                                            <p className="main_cont">{ scout.content }</p>
                                                                                           
                                            {scout.recruitments && scout.recruitments.map((recruitment: any) => (
                                                <div className="indent">
                                                    <h4>■ 求人情報
                                                        { recruitment.applied ?
                                                            <span>応募済み</span>
                                                        :<></>}
                                                    </h4> 
                                                    <img src={recruitment.thumbnail ? recruitment.thumbnail : ''} alt="recruitment.thumbnail"/>
                                                    <p>
                                                        職種名：{ recruitment.job_title }<br/>
                                                        職種：{ recruitment.job_category_name }<br/>
                                                        給与：{ recruitment.salary_form } 【{ Number(recruitment.lower_monthly_basic_salary).toLocaleString() } 〜 { Number(recruitment.upper_monthly_basic_salary).toLocaleString() } 円】<br/>
                                                        業務内容：<span className="address">{recruitment.business_content}</span><br/>
                                                        勤務地：{ recruitment.prefecture } { recruitment.city }<br/>
                                                        雇用形態：{ recruitment.employment_status }<br/>
                                                        <span>
                                                            <a href={'/recruitments/' + recruitment.id} className="btn type3" target="_blank">求人の詳細はこちら</a>
                                                            { !recruitment.applied ?
                                                                <a href={'/recruitments/' + recruitment.id + '/entry/' } className="btn type3" target="_blank">エントリーする</a>
                                                            :<a></a>}
                                                            { recruitment.applied ?
                                                                <a className="btn type3 gray">エントリー済み</a>
                                                            :<></>}
                                                        </span>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>                                            
                                        <div className="company_box">
                                            <div className="img_box">
                                                <img src={scoutsData.company.logo == '' ? '/assets/img/company/img02.jpg' : scoutsData.company.logo} alt={scoutsData.company.name}/>
                                            </div>
                                            <div className="text_box">
                                                <h5>{ scoutsData.company.name }</h5>
                                                <h6>〒 { scoutsData.company.postcode }<br/>{ scoutsData.company.address }</h6>
                                                <p>
                                                    { companyBusinessContent() }
                                                    <a href={'/recruitments/' + scout.recruitments.id} onClick={setActiveTabForRecruitmentsPage}>会社概要はこちら</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div> 
                                </li>
                            ))} 
                        </ul>
                        {scouts ?
                        <ul className="pagination">
                            {currentPage > 1 ?
                            <li className="arrow prev">
                                <a href="javascript:void(0)" onClick={pageDecrement}>
                                    <img src="/assets/img/company/icon06.svg" alt="前へ" />
                                </a>
                            </li>
                            : <></>}
                            {pageNumbers.map((p) => (
                                <li key={p} className={currentPage === p ? 'active' : ''}>
                                    <a href="javascript:void(0)" onClick={() => setCurrentPageLoad(p)}>
                                        <span>{p}</span>
                                    </a>
                                </li>
                            ))}
                            {currentPage < total_pages ?
                            <li className="arrow next">
                                <a href="javascript:void(0)" onClick={pageIncrement}>
                                    <img src="/assets/img/company/icon07.svg" alt="次へ" />
                                </a>
                            </li>
                            : <></>}
                        </ul>
                        : <></>}
                    </div>
                :<></>}
                <div className="cont3">
                    <h3>この企業の求人一覧</h3>
                    <ul className="slider">
                        {scoutsData.recruitments && scoutsData.recruitments.map((recruitment: any) => (
                            <li key={recruitment.id}>
                                <a href={'/recruitments/' + recruitment.id }>
                                    <div className="img_box">
                                        <img src={recruitment.thumbnail ? recruitment.thumbnail : '/assets/img/jobs/detail_nophoto.png'} alt={recruitment.job_title}/>
                                    </div>
                                    <h4 className="ttl">{ recruitment.job_title }／{ recruitment.job_category_name }</h4>
                                    <p>{ recruitment.salary_form } 【{ recruitment.lower_monthly_basic_salary.toLocaleString() } 〜 { recruitment.upper_monthly_basic_salary.toLocaleString() } 円】</p>
                                    <p className="category">{ recruitment.employment_status }</p>
                                    <p className="new_graduate">{ recruitment.new_graduate }</p>
                                    <p>{ recruitment.prefecture } { recruitment.city }</p>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="cont4">
                    <a href={'/mypage/scouts'} className="btn">一覧へ戻る</a>
                    <a onClick={notificationAlert}>不適切なスカウト内容のため、管理者へ報告する</a>
                </div>
            </div>
        </section>
    </main>
    );  
}
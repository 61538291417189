import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchApplicantRequest } from '../../../redax/actions/applicantActions/applicantActions';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';
import { fetchFavCountRequest } from "../../../redax/actions/favCountActions/favCountActions";

function MypageIndex() {
    const dispatch = useDispatch();
    const [jsonObject, setJsonObject] = useState<any>(null);
    const [news, setNews] = useState<any[]>([]);
    const [oldNews, setOldNews] = useState<any>([]);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [authenticate, setAuthenticate] = useState(null);
    const [url, setUrl] = useState('');
    const [filteredNews, setFilteredNews] = useState<any[]>([]);
    const [schedules, setSchedules] = useState<any[]>([]);
    const [applicationRecruitments, setApplicationRecruitments] = useState<any[]>([]);
    const [recommendRecruitments, setRecommendRecruitments] = useState<any[]>([]);
    const [show, setShow] = useState<{ [key: number]: boolean }>({});
    const [slide, setSlide] = useState(0);
    const recommend_modals: any[] = [];
    const [loadScreen, setLoadScreen] = useState(true);
    const [totalPage, setTotalPage] = useState<any>(10);
    const [isRecommendModalOpen, setModalOpen] = useState<any>(false);
    const favCount = useSelector((state: RootState) => state.favCount);

    const [count, setCount] = useState<any>(null);
    let perPage = 5;
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/login');
      } else {
        if(localStorage.getItem('load') == '1') {
          localStorage.setItem('load', '2');
          window.location.reload();
        }
        // dispatch(fetchLoginRequest('', '',''));
      }
    }, [navigate, dispatch]);
  


    const loginData = useSelector((state: RootState) => state.login);
    const applicant = useSelector((state: RootState) => state.applicant);
  
    useEffect(() => {
      if (loginData.pending) {
        if(!loginData.login.is_init_complete) {
          // navigate('/mypage/init/');
          window.location.href = '/mypage/init/';
        }
        dispatch(fetchApplicantRequest(loginData.login.resource.id));
        setResourceId(loginData.login.resource.id);
        setFamylyName(loginData.login.resource.family_name);
        // setAuthenticate(loginData.login.authenticate.resource_type);
      }
    }, [loginData.pending]);

    useEffect(() => {
      let tmp: any = [];
      let tmp1: any = [];
      let tmp2: any = [];

      if (applicant.pending) {
        setNews(applicant.applicant.news);
        if(applicant.applicant.schedules !== undefined){
          tmp1 = applicant.applicant.schedules;
        }
        if(applicant.applicant.application_recruitments !== undefined) {
          tmp = applicant.applicant.application_recruitments; 
        }
        if(applicant.applicant.recommend_recruitments !== undefined) {
          tmp2 = applicant.applicant.recommend_recruitments; 
        }
        setApplicationRecruitments(tmp);
        setSchedules(tmp1);
        setRecommendRecruitments(tmp2);
        setLoadScreen(false);
      } else if(applicant.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [applicant]);

    useEffect(() => {
      const startIndex = (page - 1) * perPage;
      const endIndex = page * perPage;
      if (news) {
        const tmp = news.slice(startIndex, endIndex);
        setFilteredNews(tmp);
        const totalPage =  Math.ceil(news.length / 5)
        setTotalPage(totalPage);
        const count = news.length
        setCount(count);

      }
    }, [news, page]);


    useEffect(() => {
      let tmpe = [];
      if(news && loginData.pending) {
         if(!loginData.login.is_active && news) {
              for(let i = 0; i< news.length; i++) {
                  if(news[i].is_read){
                      tmpe.push(news[i]);
                  }
              }
         }
      }
      setOldNews(tmpe);
  }, [news,loginData]);

    function logout() {
      if (localStorage.entryFlag) {
        localStorage.removeItem('entryFlag')
      }
      if (localStorage.jobParachannelAuthToken) {
        localStorage.removeItem('jobParachannelAuthToken')
        navigate('/');
        window.location.reload();
      }
    }
    
    const newsToggle = (index: any) => {
      setShow((prevShow) => ({
        ...prevShow,
        [index]: !prevShow[index],
      }));
    };

    async function newsLink(news: any, url: any) {
      if (news.read_url) {
        console.log(news.read_url);
        // try {
        //   await axios.post(news.read_url, {}, {
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: localStorage.jobParachannelAuthToken,
        //     },
        //   });
        // } catch (error) {
        //   console.error('Failed to mark news as read:', error);
        // }
      }
    
      const sameUrlLink = url.startsWith(process.env.REACT_APP_BASE_URL);
      if (sameUrlLink) {
        window.location.href = url.replace(process.env.REACT_APP_BASE_URL, '');
      } else {
        window.open(url, '_blank');
      }
    }

    const onPrev = () => {
      if (page > 1) {
        setPage(page - 1);
      }
    };
  
    const onNext = () => {
      if (page < totalPage) {
        setPage(page + 1);
      }
    };

    const nextSlide = () => {
      if (slide + 1 >= recommend_modals.length) {
        setSlide(0);
        return;
      }
      setSlide(slide + 1);
    };
  
    const prevSlide = () => {
      if (slide <= 0) {
        setSlide(recommend_modals.length - 1);
        return;
      }
      setSlide(slide - 1);
    };
  
    const replacedContent = (value: any) => {
      if (value == null) {
        return null;
      }
      return value.replaceAll('\n', '<br>');
    };
  
    const fetchFavorite = (recruitmentId:any) => {
      dispatch(fetchFavCountRequest(recruitmentId, 'post', 'recruitment'));
    };

    return (
      
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="#">マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">マイページTOP</h2>
                <ul>
                <li><a href="javascript:void(0)" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div className="top mypage">
                <div>
                  <h2 className="ttl">お知らせ</h2>
                  {news && news.length == 0 ? 
                  <ul className="cont1">
                      <li>
                          <div><p>お知らせはありません</p></div>
                      </li>
                  </ul>: 
                  <>
                  {loginData.login.is_active ?
                  <ul className="cont1 news">
                        {filteredNews.map((news:any, index:any) => (
                          <li key={`news-${index}`} className={` ${show[index] ? 'active' : ''} ${news.is_scout && !news.is_read ? 'scout' : ''}`}>
                            <a href="#" onClick={(e) => { e.preventDefault(); newsToggle(index); }}>
                              <p className="date">{news.date}</p>
                              { !news.is_read && <p className="new">NEW</p> }
                              <p>{news.title}</p>
                            </a>
                            { show[index] && (
                              <div>
                                { news.message && <p>{news.message}</p> }
                                { news.url && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.url); }}>{news.content}</a>
                                )}
                                { !news.url && news.content && <p>{news.content}</p> }
                                { news.sub_url1 && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url1); }}>{news.sub_content1}</a>
                                )}
                                { !news.sub_url1 && news.sub_content1 && <p>{news.sub_content1}</p> }
                                { news.sub_url2 && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url2); }}>{news.sub_content2}</a>
                                )}
                                { !news.sub_url2 && news.sub_content2 && <p>{news.sub_content2}</p> }
                              </div>
                            )}
                          </li>
                        ))}
                  </ul>
                  :
                  <ul className="cont1 news">
                        {oldNews.map((news:any, index:any) => (
                          <li key={`news-${index}`} className={` ${show[index] ? 'active' : ''} ${news.is_scout && !news.is_read ? 'scout' : ''}`}>
                            <a href="#" onClick={(e) => { e.preventDefault(); newsToggle(index); }}>
                              <p className="date">{news.date}</p>
                              { !news.is_read && <p className="new">NEW</p> }
                              <p>{news.title}</p>
                            </a>
                            { show[index] && (
                              <div>
                                { news.message && <p>{news.message}</p> }
                                { news.url && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.url); }}>{news.content}</a>
                                )}
                                { !news.url && news.content && <p>{news.content}</p> }
                                { news.sub_url1 && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url1); }}>{news.sub_content1}</a>
                                )}
                                { !news.sub_url1 && news.sub_content1 && <p>{news.sub_content1}</p> }
                                { news.sub_url2 && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url2); }}>{news.sub_content2}</a>
                                )}
                                { !news.sub_url2 && news.sub_content2 && <p>{news.sub_content2}</p> }
                              </div>
                            )}
                          </li>
                        ))}
                  </ul>
                  }
                    
                  </>
                  
                  }
                   <div className="btn_box">
                      <a href="#" onClick={onPrev} className={page <= 1 ? 'disabled' : ''}>
                        <img src="/assets/img/company/icon19.svg" alt="" />
                      </a>
                      <a href="#" onClick={onNext} className={page >= totalPage ? 'disabled' : ''}>
                        <img src="/assets/img/company/icon20.svg" alt="" />
                      </a>
                    </div>
                </div>

                <div>
                  <h2 className="ttl">直近のスケジュール</h2>
                  { schedules.length == 0 ?
                    <ul className="cont1">
                      <li>
                        <div><p>スケジュールはありません</p></div>
                      </li>
                    </ul>
                  : 
                  <ul className="cont3 schedule">
                     {schedules.map((schedule:any, index:any) => (
                      <li key={'schedule'+{index}}>
                        <div>
                          <p className="date">{ schedule.date }</p>
                          <p className="category">{ schedule.status }</p>
                          <p>{ schedule.job_title }</p>
                        </div>
                      </li>
                     ))}
                  </ul>
                  }
                  {schedules.length  ? <a href="/mypage/schedules/" className="btn type3">もっと見る</a> : <></>} 
                </div>
                <div>
                  <h2 className="ttl">応募済みの求人</h2>
                  {applicationRecruitments.length === 0 ?
                    <ul className="cont1" v-if="application_recruitments_list.length == 0">
                      <li>
                        <div><p>応募済みの求人はありません</p></div>
                      </li>
                    </ul>
                  :
                  <ul className="cont1">
                  {applicationRecruitments.map((application_recruitment:any, index:any) => (
                      <li key={'application_recruitment'+{index}}>
                       <a href= {'/mypage/applications/' + application_recruitment.id}>
                        <p className='date'>{ application_recruitment.company_name }</p>
                        <p>{ application_recruitment.job_title }</p>
                       </a>
                      </li>
                     ))}
                  </ul>
                  }
                  {applicationRecruitments.length ? <a href="/mypage/applications/" className="btn type3" v-if="application_recruitments_list.length">もっと見る</a> : <></>}    
                  
                </div>

                <div>
                <h2 className="ttl">あなたにおすすめの求人</h2>
                {recommendRecruitments.length === 0 ?
                  <ul className="cont4" v-if="recommend_recruitments_list.length == 0">
                    <li>
                      <div><p>あなたにおすすめの求人はありません</p></div>
                    </li>
                  </ul>
                :
                <ul className="cont1">
                    {recommendRecruitments.map((recommend_recruitment:any, index:any) => (
                      <li key={'recommend_recruitment'+{index}}>
                       <a href= {'/recruitments/' + recommend_recruitment.id}>
                        <p className="date">{ recommend_recruitment.company_name }</p>
                        <p>{ recommend_recruitment.job_title }</p>
                       </a>
                      </li>
                     ))}
                </ul> 
                }
              </div>
            </div>
         
            </section>
      </main>
    );
  }
  
  export default MypageIndex;
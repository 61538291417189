import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminNav from '../adminTop/AdminNav';
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchAdminCompanyListRequest } from '../../redax/actions/adminCompanyListActions/adminCompanyListActions';
import Loader from '../loader/loader';

const AdminCompanyDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();    
    const [current_page, setCurrentPage] = useState(1);
    const [companyList, setCompanyList] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [total_pages, setTotalPages] = useState(1);
    const [keyword, setKeyword] = useState(''); 
    const companies = useSelector((state:RootState) => state.adminCompanyList);
    const [loadScreen, setLoadScreen] = useState(true);
    const [putResponse, setPutResponse] = useState(false);

    useEffect(() => {
        setLoadScreen(false)
        // getCompanies(1,'')
    }, [dispatch]); 

    // const getCompanies = (page:any, keyword:string) => {
    //     setLoadScreen(true);
    //     const data = {
    //         "page": page,
    //         "keyword": keyword ? keyword : '',
    //     }
    //     if(data != null) {
    //         dispatch(fetchAdminCompanyListRequest(data));
    //         if(companies.pagenation !== undefined) {
    //             setCurrentPage(companies.pagenation.current_page);
    //             setTotalPages(companies.pagenation.total_pages);
    //           }
    //     }
    //     setLoadScreen(false)
    // }

    // useEffect(() => {
    //     if(companies.companies) {
    //         setLoadScreen(false);
    //         setCompanyList(companies.companies)

    //         if(companies.pagenation !== undefined) {
    //             setCurrentPage(companies.pagenation.current_page);
    //             setTotalPages(companies.pagenation.total_pages);
    //           }
    //     } else if(companies.error != null) {
    //         navigate('/error?code=500');
    //     }
    // }, [companies]);


  return (
    <main id='adminHome'>
      <AdminNav/>
      {loadScreen ? <Loader></Loader> : <></>}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">企業詳細</h2>
            <div className="inputBox">
              
            </div>
          </div>   
          <div className="body">
            <div className="companyDetailWrapper">
              <div className="header">
              </div>
              <div className="body">
              </div>
            </div>
          </div> 
        </div>      
      </section>
    </main>
  )
}

export default AdminCompanyDetail

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import Loader from '../../loader/loader';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchAdminNewsNewRequest } from '../../../redax/actions/adminNewsNewActions/adminNewsNewActions';

interface News {
    title: string;
    message: string;
    url: string;
    content: string;
    target_type: number;
    applicant_id?: number;
    company_id?: number;
    sub_url1?: string;
    sub_content1?: string;
    sub_url2?: string;
    sub_content2?: string;
  }
  
  interface Props {
    h3_title?: string;
    applicant_id?: number;
    company_id?: number;
    target_type?: number;
  }

  const AdminNewsNew: React.FC<Props> = ({ h3_title, applicant_id, company_id, target_type }) => {
  const [loadScreen, setLoadScreen] = useState(false);
  const adminNewsNew = useSelector((state: RootState) => state.adminNewsNew);
  const dispatch = useDispatch();
  const [news, setNews] = useState<News>({
    title: '',
    message: '',
    url: '',
    content: '',
    target_type: target_type ?? 1,
    applicant_id: applicant_id ?? undefined,
    company_id: company_id ?? undefined,
  });
  const navigate = useNavigate();
  const [showSubNew1, setShowSubNew1] = useState(false);
  const [showSubNew2, setShowSubNew2] = useState(false);
  const [chk, setSChk] = useState(false);

//   const history = useHistory();

useEffect(() => {
  if (adminNewsNew.pending && chk) {
    console.log(adminNewsNew);
    alert("お知らせを作成しました")
    navigate('/admin/news/');
  }
}, [adminNewsNew,chk]);

const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNews((prevNews) => ({ ...prevNews, [name]: value }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let validationMessages = '';
    if (!news.title) {
      validationMessages += 'タイトル\n';
    }
    if (!news.message) {
      validationMessages += '本文\n';
    }

    if (validationMessages) {
      alert('未入力項目があります\n\n' + validationMessages);
      return;
    }

    if (!window.confirm('この内容でお知らせを送信しますか？')) {
      return;
    } 
    dispatch(fetchAdminNewsNewRequest(news));
    setSChk(true);
  
  };
  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section id='news'>
        <div className="adminPageWrapper">
            <div className="header">
                <h2 className="title">お知らせの新規作成 </h2>
            </div>   
                <div className='body'>
                    <div className="">
                        <div className="textWrapper">
                        <a  onClick={() => navigate('/admin/news')} className='blueBtn'>＜ 一覧に戻る</a>
                        </div>
                    </div>
                        {/* <form onSubmit={handleSubmit}> */}
                            <label>タイトルを入力</label>
                            <input
                            type="text"
                            name="title"
                            placeholder="タイトルを入力してください"
                            value={news.title}
                            onChange={handleChange}
                            />
                            <label>お知らせ内容を入力</label>
                            <textarea
                            name="message"
                            placeholder="お知らせしたい内容を入力してください"
                            value={news.message}
                            onChange={handleChange}
                            />
                            <label htmlFor="">URLを入力</label>
                            <input
                            type="text"
                            name="url"
                            placeholder="URLを入力してください"
                            value={news.url}
                            onChange={handleChange}
                            />
                            <label htmlFor="">リンクの文字列を入力</label>
                            <input
                            type="text"
                            name="content"
                            placeholder="リンクの文字列を入力してください"
                            value={news.content}
                            onChange={handleChange}
                            />

                        {!showSubNew1 && (
                        <button className='newsBtnToggle' type="button" onClick={() => setShowSubNew1(true)}>
                            お知らせを追加する
                        </button>
                        )}

                        {showSubNew1 && (
                        <div id="sub_new1">
                            <label htmlFor="">URLを入力</label>
                            <input
                            type="text"
                            name="sub_url1"
                            placeholder="URLを入力してください"
                            value={news.sub_url1 ?? ''}
                            onChange={handleChange}
                            />
                            <label htmlFor="">リンクの文字列を入力</label>
                            <input
                            type="text"
                            name="sub_content1"
                            placeholder="リンクの文字列を入力してください"
                            value={news.sub_content1 ?? ''}
                            onChange={handleChange}
                            />

                            {!showSubNew2 && (
                            <button className='newsBtnToggle' type="button" onClick={() => setShowSubNew2(true)}>
                                お知らせを追加する
                            </button>
                            )}
                        </div>
                        )}

                        {showSubNew2 && (
                        <div id="sub_new2">
                            <label htmlFor="">URLを入力</label>
                            <input
                            type="text"
                            name="sub_url2"
                            placeholder="URLを入力してください"
                            value={news.sub_url2 ?? ''}
                            onChange={handleChange}
                            />
                            <label htmlFor="">リンクの文字列を入力</label>
                            <input
                            type="text"
                            name="sub_content2"
                            placeholder="リンクの文字列を入力してください"
                            value={news.sub_content2 ?? ''}
                            onChange={handleChange}
                            />
                        </div>
                        )}
                        <div className="combo_group">
                            <label htmlFor="target_type">対象タイプ</label>
                            <select
                                name="target_type"
                                value={news.target_type}
                                onChange={handleChange}
                            >
                                <option value={1}>求職者</option>
                                <option value={2}>企業</option>
                                <option value={3}>両方</option>
                            </select>
                        </div>
                        <button onClick={handleSubmit} className="btn type4 blue">
                        送信
                        </button>
                    {/* </form> */}
                </div>
        </div>
      </section>
    </main>
  );
};

export default AdminNewsNew;

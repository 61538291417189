import React, { useEffect } from 'react'
import "../matome/common.scss"
import "../matome/matomeReset.scss"
import "../matome/matomeStyle.css"
import "../matome/matomeStyle.scss"
import "../matome/matomeStyle.css.map"

const MatomeDetail = () => {

    const pageTitle = "多発性硬化症（MS)専用ののリクルートサイト";
    document.title = pageTitle;

    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&family=Noto+Color+Emoji&family=Noto+Sans+JP:wght@100;300;400;700&display=swap&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
  
          const script = document.createElement('script');      
          script.src = "/assets/js/main.js";
          document.body.appendChild(script);
      
          const script0 = document.createElement('script');
          script0.type = "text/javascript";
          script0.src ="/assets/js/seamless.js";
          document.head.appendChild(script0);
      
          return () => {
            document.body.removeChild(script);
            document.head.removeChild(script0);
      
          }
      }, []);
  return (
    <div className='matomeBody'>
        <div className="is_page_detail">
            <header id="header" className="header">
            <div className="header_container">
                <div className="header_logo-wrap">
                <div className="header_logo">
                    <div className="top">
                    <span className="text">多発性硬化症（MS)専用の</span>
                    </div>

                    <div className="bottom">
                    <span className="text1">リクルートサイト</span>
                    <span className="text2">by パラちゃんねる</span>
                    </div>
                </div>
                </div>

                <div className="sp_menu_button-wrap">
                <button id="sp_menu_button" className="sp_menu_button" type="button" role="button" data-labeltext="ハンバーガーメニュー" aria-controls="sp_menu_popup" aria-expanded="false" aria-label="ハンバーガーメニュー" aria-haspopup="true">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                </div>

                <nav className="pc_menu-wrap" role="navigation" aria-label="グローバルメニュー">
                <ul id="pc_menu" className="pc_menu" role="list">
                    <li className="pc_menu_link_li">
                    <a className="pc_menu_link" href="/matome"><span style={{color:"black"}}>HOME</span></a>
                    </li>
                    <li className="pc_menu_link_li">
                    <div className="has_sub_menu">
                        <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu1" aria-haspopup="true"><span style={{color:"black"}}>身体障害</span></a>
                    </div>
                    <div id="pc_menu1" className="pc_sub_menu-wrap" aria-hidden="true">
                        <ul className="pc_sub_menu" role="list">
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=1">視覚障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=2">構音障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=3">吃音症</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=4">失語症</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=5">膀胱・直腸機能障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=6">内部障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=7">上肢・下肢不自由</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=8">言語機能障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=9">脳性まひ</a></li>
                        </ul>
                    </div>
                    </li>
                    <li className="pc_menu_link_li">
                    <div className="has_sub_menu">
                        <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu2" aria-haspopup="true"><span style={{color:"black"}}>発達障害</span></a>
                    </div>
                    <div id="pc_menu2" className="pc_sub_menu-wrap" aria-hidden="true">
                        <ul className="pc_sub_menu" role="list">
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=10">ディスレクシア（読字障害）</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=11">感覚障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=12">ディスカルキュリア（算数障害）</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=13">学習障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=14">注意欠如多動性障害（ADHD）</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=15">自閉症スペクトラム症（ASD）</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=16">発達障害</a></li>
                        </ul>
                    </div>
                    </li>
                    <li className="pc_menu_link_li">
                    <div className="has_sub_menu">
                        <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu3" aria-haspopup="true"><span style={{color:"black"}}>精神障害</span></a>
                    </div>
                    <div id="pc_menu3" className="pc_sub_menu-wrap" aria-hidden="true">
                        <ul className="pc_sub_menu" role="list">
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=17">うつ病</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=18">てんかん</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=19">心的外傷後ストレス（PTSD）</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=20">高次脳機能障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=21">双極性障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=22">不安障害</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=23">統合失調症</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=24">摂食障害</a></li>
                        </ul>
                    </div>
                    </li>
                    <li className="pc_menu_link_li">
                    <div className="has_sub_menu">
                        <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu4" aria-haspopup="true"><span style={{color:"black"}}>知的障害</span></a>
                    </div>
                    <div id="pc_menu4" className="pc_sub_menu-wrap" aria-hidden="true">
                        <ul className="pc_sub_menu" role="list">
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=25">ちてきしょうがい</a></li>
                        </ul>
                    </div>
                    </li>
                    <li className="pc_menu_link_li">
                    <div className="has_sub_menu">
                        <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu5" aria-haspopup="true"><span style={{color:"black"}}>難病指定</span></a>
                    </div>
                    <div id="pc_menu5" className="pc_sub_menu-wrap last_pc_sub_menu" aria-hidden="true">
                        <ul className="pc_sub_menu" role="list">
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=26">筋萎縮性側索硬化症（神経難病含む）</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=27">多発性硬化症（MS）</a></li>
                        <li><a className="pc_sub_menu_link" href="/matome/detail/?v=28">指定難病1～331番</a></li>
                        </ul>
                    </div>
                    </li>

                </ul>
                </nav>
            </div>
            </header>

            <div id="sp_menu_popup" className="sp_menu-wrap" aria-hidden="true">
        <nav className="sp_menu-inner" aria-label="グローバルメニュー">
            <ul className="sp_menu" role="list">
            <li>
                <a className="sp_menu_link sp_menu_link_arrow" href="matome" role="button"><span>HOME</span></a>
            </li>
            <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu1"><span>身体障害</span></a>
                </div>
                <div id="sp_menu1" className="sp_sub_menu-wrap " aria-hidden="true">
                <ul className="sp_sub_menu" role="list">
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=1">視覚障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=2">構音障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=3">吃音症</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=4">失語症</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=5">膀胱・直腸機能障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=6">内部障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=7">上肢・下肢不自由</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=8">言語機能障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=9">脳性まひ</a></li>
                </ul>
                </div>
            </li>
            <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu2"><span>発達障害</span></a>
                </div>
                <div id="sp_menu2" className="sp_sub_menu-wrap" aria-hidden="true">
                <ul className="sp_sub_menu" role="list">
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=10">ディスレクシア（読字障害）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=11">感覚障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=12">ディスカルキュリア（算数障害）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=13">学習障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=14">注意欠如多動性障害（ADHD）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=15">自閉症スペクトラム症（ASD）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=16">発達障害</a></li>
                </ul>
                </div>
            </li>
            <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu3"><span>精神障害</span></a>
                </div>
                <div id="sp_menu3" className="sp_sub_menu-wrap" aria-hidden="true">
                <ul className="sp_sub_menu" role="list">
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=17">うつ病</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=18">てんかん</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=19">心的外傷後ストレス（PTSD）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=20">高次脳機能障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=21">双極性障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=22">不安障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=23">統合失調症</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=24">摂食障害</a></li>
                </ul>
                </div>
            </li>
            <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu4"><span>知的障害</span></a>
                </div>
                <div id="sp_menu4" className="sp_sub_menu-wrap" aria-hidden="true">
                <ul className="sp_sub_menu" role="list">
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=25">ちてきしょうがい</a></li>
                </ul>
                </div>
            </li>
            <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu5"><span>難病指定</span></a>
                </div>
                <div id="sp_menu5" className="sp_sub_menu-wrap" aria-hidden="true">
                <ul className="sp_sub_menu" role="list">
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=26">筋萎縮性側索硬化症（神経難病含む）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=27">多発性硬化症（MS）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=28">指定難病1～331番</a></li>
                </ul>
                </div>
            </li>
            </ul>
            <div className="sp_menu_logo-wrap">
            <picture className="sp_menu_logo" aria-hidden="true">
                <img src="/assets/img/footer_logo.png" alt="障がい者雇用の求人サイト パラちゃんねる" width="332" height="272" loading="lazy"/>
            </picture>
            </div>
        </nav>
            </div>

        <main>
            <div className="section_detail_mv">
            <div className="contents_container">
                <div className="mv_title-wrap">
                <h1 className="mv_title">多発性硬化症<br aria-hidden="true"/>（MS)に配慮がある<br aria-hidden="true"/>お仕事まとめ
                
                    <div className="update_balloon-wrap">
                    <div className="update_balloon">
                        <span>2023.12.25</span>
                        <span>UPDATE</span>
                    </div>
                    </div>
                
                </h1>
                </div>

                <div className="lead_text-wrap">
                <h2 className="lead_text"><span className="title_bg">障がい者雇用の求人サイト「パラちゃんねる」で</span><br className=""/>
                    
                    <span className="title_bg">掲載中のものから、「多発性硬化症（MS）に配慮がある」と、</span><br className=""/>
                
                    <span className="title_bg">回答いただいた企業様の募集職種だけをまとめました。</span>
                </h2>
                </div>

                <div className="note_text-wrap">
                <p className="note_text">※アンケートの回答がいただけていない企業様もたくさんございます。<br aria-hidden="true"/>
                    身体障がい全体での検索は「パラちゃんねる」の本サイトをご覧ください。</p>
                <p className="note_text">※各種リンク先（お仕事詳細）は、パラちゃんねる本体ページが、別タブで開きます。</p>
                </div>

                <picture className="mv_logo" aria-hidden="true">
                <img src="/assets/img/logo01.png" width="160" height="150" alt="障がい者雇用の求人サイト パラちゃんねる"/>
                </picture>
            </div>
            </div>

            <section className="section03">
            <div className="contents_container">
                <div className="list_toolbox" aria-label="ページ数と並び替えナビゲーション">
                <div className="order_link_select-wrap">
                    <select name="" className="order_link_select">
                    <option>新着順</option>
                    <option>ログイン企業順</option>
                    <option>年収の高い順</option>
                    <option>雇用実績順</option>
                    </select>
                </div>

                <div className="pagination_count-wrap">
                    <div className="pagination_count">50 / 1000件</div>
                </div>

                <div className="order_link-wrap">
                    <a className="order_link is_active" href="#" aria-current="page">新着順</a>
                    <a className="order_link " href="#">ログイン企業順</a>
                    <a className="order_link " href="#">年収の高い順</a>
                    <a className="order_link " href="#">雇用実績順</a>
                </div>
                </div>

                <div className="job_list-wrap">
                <ul className="job_list" role="list">
                                    <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                        <div className="card_top">
                            <div className="job_status-wrap_layer">
                            <div className="job_status-wrap">
                                <span className="job_status">最終更新：24時間以内</span>
                                <span className="job_status">新着求人</span>
                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                            <picture className="job_thumbnail">
                                <img src="/assets/img/dummy01.png" alt="" width="" height="" loading="lazy"/>
                            </picture>
                            </div>
                        </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">パート・アルバイト</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">言語聴覚士</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>言語聴覚士</li>
                                <li>月給 【155,000 〜 175,000 円】</li>
                                <li>勤務地：北海道 札幌市厚別区厚別南</li>
                            </ul>

                            <p className="job_company_name">合同会社 愛彩</p>
                            </div>
                        </a>
                        </li>
                        <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                            <div className="card_top">
                            <div className="job_status-wrap_layer">
                                <div className="job_status-wrap">
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                                <picture className="job_thumbnail">
                                <img src="/assets/img/dummy02.png" alt="" width="" height="" loading="lazy"/>
                                </picture>
                            </div>
                            </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">（障）看護師</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>利用者の健康管理、服薬の指導／体温、血圧、脈拍数などを測りカルテに記録／利用者の容体等のチェック</li>
                                <li>時間給 【1,200 〜 1,550 円】</li>
                                <li>勤務地：三重県 伊賀市ゆめが丘</li>
                            </ul>

                            <p className="job_company_name">社会福祉法人 グリーンセンター福祉会</p>
                            </div>
                        </a>
                        </li>
                                    <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                        <div className="card_top">
                            <div className="job_status-wrap_layer">
                            <div className="job_status-wrap">
                                <span className="job_status">最終更新：24時間以内</span>
                                <span className="job_status">新着求人</span>
                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                            <picture className="job_thumbnail">
                                <img src="/assets/img/dummy01.png" alt="" width="" height="" loading="lazy"/>
                            </picture>
                            </div>
                        </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">パート・アルバイト</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">言語聴覚士</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>言語聴覚士</li>
                                <li>月給 【155,000 〜 175,000 円】</li>
                                <li>勤務地：北海道 札幌市厚別区厚別南</li>
                            </ul>

                            <p className="job_company_name">合同会社 愛彩</p>
                            </div>
                        </a>
                        </li>
                        <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                            <div className="card_top">
                            <div className="job_status-wrap_layer">
                                <div className="job_status-wrap">
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                                <picture className="job_thumbnail">
                                <img src="/assets/img/dummy02.png" alt="" width="" height="" loading="lazy"/>
                                </picture>
                            </div>
                            </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">（障）看護師</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>利用者の健康管理、服薬の指導／体温、血圧、脈拍数などを測りカルテに記録／利用者の容体等のチェック</li>
                                <li>時間給 【1,200 〜 1,550 円】</li>
                                <li>勤務地：三重県 伊賀市ゆめが丘</li>
                            </ul>

                            <p className="job_company_name">社会福祉法人 グリーンセンター福祉会</p>
                            </div>
                        </a>
                        </li>
                                    <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                        <div className="card_top">
                            <div className="job_status-wrap_layer">
                            <div className="job_status-wrap">
                                <span className="job_status">最終更新：24時間以内</span>
                                <span className="job_status">新着求人</span>
                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                            <picture className="job_thumbnail">
                                <img src="/assets/img/dummy01.png" alt="" width="" height="" loading="lazy"/>
                            </picture>
                            </div>
                        </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">パート・アルバイト</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">言語聴覚士</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>言語聴覚士</li>
                                <li>月給 【155,000 〜 175,000 円】</li>
                                <li>勤務地：北海道 札幌市厚別区厚別南</li>
                            </ul>

                            <p className="job_company_name">合同会社 愛彩</p>
                            </div>
                        </a>
                        </li>
                        <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                            <div className="card_top">
                            <div className="job_status-wrap_layer">
                                <div className="job_status-wrap">
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                                <picture className="job_thumbnail">
                                <img src="/assets/img/dummy02.png" alt="" width="" height="" loading="lazy"/>
                                </picture>
                            </div>
                            </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">（障）看護師</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>利用者の健康管理、服薬の指導／体温、血圧、脈拍数などを測りカルテに記録／利用者の容体等のチェック</li>
                                <li>時間給 【1,200 〜 1,550 円】</li>
                                <li>勤務地：三重県 伊賀市ゆめが丘</li>
                            </ul>

                            <p className="job_company_name">社会福祉法人 グリーンセンター福祉会</p>
                            </div>
                        </a>
                        </li>
                                    <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                        <div className="card_top">
                            <div className="job_status-wrap_layer">
                            <div className="job_status-wrap">
                                <span className="job_status">最終更新：24時間以内</span>
                                <span className="job_status">新着求人</span>
                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                            <picture className="job_thumbnail">
                                <img src="/assets/img/dummy01.png" alt="" width="" height="" loading="lazy"/>
                            </picture>
                            </div>
                        </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">パート・アルバイト</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">言語聴覚士</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>言語聴覚士</li>
                                <li>月給 【155,000 〜 175,000 円】</li>
                                <li>勤務地：北海道 札幌市厚別区厚別南</li>
                            </ul>

                            <p className="job_company_name">合同会社 愛彩</p>
                            </div>
                        </a>
                        </li>
                        <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                            <div className="card_top">
                            <div className="job_status-wrap_layer">
                                <div className="job_status-wrap">
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                                <picture className="job_thumbnail">
                                <img src="/assets/img/dummy02.png" alt="" width="" height="" loading="lazy"/>
                                </picture>
                            </div>
                            </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">（障）看護師</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>利用者の健康管理、服薬の指導／体温、血圧、脈拍数などを測りカルテに記録／利用者の容体等のチェック</li>
                                <li>時間給 【1,200 〜 1,550 円】</li>
                                <li>勤務地：三重県 伊賀市ゆめが丘</li>
                            </ul>

                            <p className="job_company_name">社会福祉法人 グリーンセンター福祉会</p>
                            </div>
                        </a>
                        </li>
                                    <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                        <div className="card_top">
                            <div className="job_status-wrap_layer">
                            <div className="job_status-wrap">
                                <span className="job_status">最終更新：24時間以内</span>
                                <span className="job_status">新着求人</span>
                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                            <picture className="job_thumbnail">
                                <img src="/assets/img/dummy01.png" alt="" width="" height="" loading="lazy"/>
                            </picture>
                            </div>
                        </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">パート・アルバイト</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">言語聴覚士</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>言語聴覚士</li>
                                <li>月給 【155,000 〜 175,000 円】</li>
                                <li>勤務地：北海道 札幌市厚別区厚別南</li>
                            </ul>

                            <p className="job_company_name">合同会社 愛彩</p>
                            </div>
                        </a>
                        </li>
                        <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                            <div className="card_top">
                            <div className="job_status-wrap_layer">
                                <div className="job_status-wrap">
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                                <picture className="job_thumbnail">
                                <img src="/assets/img/dummy02.png" alt="" width="" height="" loading="lazy"/>
                                </picture>
                            </div>
                            </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">（障）看護師</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>利用者の健康管理、服薬の指導／体温、血圧、脈拍数などを測りカルテに記録／利用者の容体等のチェック</li>
                                <li>時間給 【1,200 〜 1,550 円】</li>
                                <li>勤務地：三重県 伊賀市ゆめが丘</li>
                            </ul>

                            <p className="job_company_name">社会福祉法人 グリーンセンター福祉会</p>
                            </div>
                        </a>
                        </li>
                                    <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                        <div className="card_top">
                            <div className="job_status-wrap_layer">
                            <div className="job_status-wrap">
                                <span className="job_status">最終更新：24時間以内</span>
                                <span className="job_status">新着求人</span>
                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                            <picture className="job_thumbnail">
                                <img src="/assets/img/dummy01.png" alt="" width="" height="" loading="lazy"/>
                            </picture>
                            </div>
                        </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">パート・アルバイト</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">言語聴覚士</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>言語聴覚士</li>
                                <li>月給 【155,000 〜 175,000 円】</li>
                                <li>勤務地：北海道 札幌市厚別区厚別南</li>
                            </ul>

                            <p className="job_company_name">合同会社 愛彩</p>
                            </div>
                        </a>
                        </li>
                        <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                            <div className="card_top">
                            <div className="job_status-wrap_layer">
                                <div className="job_status-wrap">
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                                <picture className="job_thumbnail">
                                <img src="/assets/img/dummy02.png" alt="" width="" height="" loading="lazy"/>
                                </picture>
                            </div>
                            </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">（障）看護師</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>利用者の健康管理、服薬の指導／体温、血圧、脈拍数などを測りカルテに記録／利用者の容体等のチェック</li>
                                <li>時間給 【1,200 〜 1,550 円】</li>
                                <li>勤務地：三重県 伊賀市ゆめが丘</li>
                            </ul>

                            <p className="job_company_name">社会福祉法人 グリーンセンター福祉会</p>
                            </div>
                        </a>
                        </li>
                                    <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                        <div className="card_top">
                            <div className="job_status-wrap_layer">
                            <div className="job_status-wrap">
                                <span className="job_status">最終更新：24時間以内</span>
                                <span className="job_status">新着求人</span>
                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                            <picture className="job_thumbnail">
                                <img src="/assets/img/dummy01.png" alt="" width="" height="" loading="lazy"/>
                            </picture>
                            </div>
                        </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">パート・アルバイト</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">言語聴覚士</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>言語聴覚士</li>
                                <li>月給 【155,000 〜 175,000 円】</li>
                                <li>勤務地：北海道 札幌市厚別区厚別南</li>
                            </ul>

                            <p className="job_company_name">合同会社 愛彩</p>
                            </div>
                        </a>
                        </li>
                        <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                            <div className="card_top">
                            <div className="job_status-wrap_layer">
                                <div className="job_status-wrap">
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                                <picture className="job_thumbnail">
                                <img src="/assets/img/dummy02.png" alt="" width="" height="" loading="lazy"/>
                                </picture>
                            </div>
                            </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">（障）看護師</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>利用者の健康管理、服薬の指導／体温、血圧、脈拍数などを測りカルテに記録／利用者の容体等のチェック</li>
                                <li>時間給 【1,200 〜 1,550 円】</li>
                                <li>勤務地：三重県 伊賀市ゆめが丘</li>
                            </ul>

                            <p className="job_company_name">社会福祉法人 グリーンセンター福祉会</p>
                            </div>
                        </a>
                        </li>
                                    <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                        <div className="card_top">
                            <div className="job_status-wrap_layer">
                            <div className="job_status-wrap">
                                <span className="job_status">最終更新：24時間以内</span>
                                <span className="job_status">新着求人</span>
                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                            <picture className="job_thumbnail">
                                <img src="/assets/img/dummy01.png" alt="" width="" height="" loading="lazy"/>
                            </picture>
                            </div>
                        </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">パート・アルバイト</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">言語聴覚士</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>言語聴覚士</li>
                                <li>月給 【155,000 〜 175,000 円】</li>
                                <li>勤務地：北海道 札幌市厚別区厚別南</li>
                            </ul>

                            <p className="job_company_name">合同会社 愛彩</p>
                            </div>
                        </a>
                        </li>
                        <li>
                        <a className="job_card" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener">
                            <div className="card_top">
                            <div className="job_status-wrap_layer">
                                <div className="job_status-wrap">
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                                <span className="job_status">最終更新：24時間以内</span>
                                    <span className="job_status">新着求人</span>
                                                            </div>
                            </div>
                            <div className="job_thumbnail-wrap">
                                <picture className="job_thumbnail">
                                <img src="/assets/img/dummy02.png" alt="" width="" height="" loading="lazy"/>
                                </picture>
                            </div>
                            </div>

                            <div className="card_bottom">
                            <div className="job_tag-wrap">
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                            <span className="job_tag">xxxx</span>
                                    <span className="job_tag">中途</span>
                                                        </div>
                            <h3 className="job_title">（障）看護師</h3>
                            <ul className="job_detail_item-wrap" role="list">
                                <li>利用者の健康管理、服薬の指導／体温、血圧、脈拍数などを測りカルテに記録／利用者の容体等のチェック</li>
                                <li>時間給 【1,200 〜 1,550 円】</li>
                                <li>勤務地：三重県 伊賀市ゆめが丘</li>
                            </ul>

                            <p className="job_company_name">社会福祉法人 グリーンセンター福祉会</p>
                            </div>
                        </a>
                        </li>
                                </ul>
                </div>

                <div className="pagination-wrap" aria-label="ページナビゲーション">
                <div className="pagination_link">
                    <a className="pagination_prev is_disable" href="" aria-label="前のページ"><span aria-hidden="true">前へ</span></a>
                </div>
                <ul className="pagination" role="list">
                    <li><a className="pagination_number is_active" href="" aria-current="page">1</a></li>
                    <li><a className="pagination_number " href="">2</a></li>
                    <li><span className="pagination_number is_disable" aria-hidden="true">…</span></li>
                    <li><a className="pagination_number " href="">9</a></li>
                    <li><a className="pagination_number " href="">10</a></li>
                </ul>
                <div className="pagination_link">
                    <a className="pagination_next" href="" aria-label="次のページ"><span aria-hidden="true">次へ</span></a>
                </div>
                </div>
            </div>
            </section>


        </main>

            <div className="sub_footer">
        <div className="sub_footer_mv-wrap">
            <picture className="sub_footer_mv">
            <source srcSet="/assets/img/img2.jpg" width="1400" height="786" media="(min-width: 990px)"/>
            <img src="/assets/img/img2_sp.jpg" alt="パラちゃんねるの特長のイメージ" width="750" height="786" loading="lazy"/>
            </picture>
        </div>

        <div className="contents_container">
            <div className="title-wrap">
            <h2 className="title">
                <span><span className="title_bg">障がい者雇用の</span></span><br aria-hidden="true" className="sp_only"/>
                <span><span className="title_bg">求人サイト</span></span><br aria-hidden="true"/>
                <span><span className="title_bg">パラちゃんねるの特長</span></span>
                </h2>
            </div>

            <div className="message_list-wrap">
            <ul className="message_list" role="list">
                <li><span className="emoji" aria-hidden="true">⭐</span>特長１：エントリー時点での志望動機が不要！</li>
                <li><span className="emoji" aria-hidden="true">⭐</span>特長２：スキルだけでなく相性を重視したマッチング</li>
                <li><span className="emoji" aria-hidden="true">⭐</span>特長３：学歴や職歴数ではなく長所や強みに焦点を当てたマイページ</li>
                <li><span className="emoji" aria-hidden="true">⭐</span>特長４：企業から直接声がかかる</li>
                <li><span className="emoji" aria-hidden="true">⭐</span>特長５：求職者も求人企業も完全無料！</li>
            </ul>
            </div>

            <div className="voice_group">
            <div className="voice_title-wrap">
                <h3 className="voice_title"><span className="emoji" aria-hidden="true">💬</span>パラちゃんねるユーザーの声</h3>
            </div>

            <div className="voice_list-wrap">
                <div className="voice_list">
                <div className="top">
                    <div className="img">
                    <img src="/assets/img/voice_profile_photo01.jpg" alt="豆塚エリさんの写真" width="161" height="160" loading="lazy"/>
                    </div>

                    <p className="profile">
                    <span className="name">豆塚 エリ｜詩人</span>
                    <span className="text">頸髄損傷・車椅子</span>
                    </p>
                </div>

                <div className="bottom">
                    <p className="profile_text">
                    一括りに「障害」と言っても、種別や個人によって状態は様々。
                    車いすユーザーの私は通勤に困難を抱えているので、在宅ワークに絞って検索をかけられるのが便利だと思いました。</p>
                </div>
                </div>

                <div className="voice_list">
                <div className="top">
                    <div className="img">
                    <img src="/assets/img/voice_profile_photo02.jpg" alt="古川諭香さんの写真" width="161" height="160" loading="lazy"/>
                    </div>

                    <p className="profile">
                    <span className="name">古川 諭香｜フリーライター</span>
                    <span className="text">心疾患（単心室症・単心房症）</span>
                    </p>
                </div>

                <div className="bottom">
                    <p className="profile_text">
                    持病や特性を、正しく理解してもらいながら働きたいという願いが叶いやすい。
                    職場の環境や就業に関する配慮なども、事前にチェックしてから応募できるので安心できます。</p>
                </div>
                </div>

                <div className="voice_list">
                <div className="top">
                    <div className="img">
                    <img src="/assets/img/voice_profile_photo03.jpg" alt="がんちゃんの写真" width="161" height="160" loading="lazy"/>
                    </div>

                    <p className="profile">
                    <span className="name">がんちゃん</span>
                    <span className="text">先天性感音難聴</span>
                    </p>
                </div>

                <div className="bottom">
                    <p className="profile_text">
                    パラちゃんねるは、求人企業側と私たち求職者側との間に発生しがちな「情報の非対称性」を解消してくれます。
                    特に、相性を重視したマッチング機能はおすすめ！</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

        <footer className="footer">

        <div className="contents_container">
            <div className="footer_logo-wrap">
            <a className="footer_logo_link" href="https://www.parachannelcafe.jp/" target="_blank" rel="noopener" aria-label="障がい者雇用の求人サイト パラちゃんねるを見る">
                <picture className="footer_logo" aria-hidden="true">
                <img src="/assets/img/footer_logo.png" alt="障がい者雇用の求人サイト パラちゃんねる" width="332" height="272" loading="lazy"/>
                </picture>
            </a>
            </div>

            <div className="footer_register-wrap">
            <a className="footer_register_btn" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener" aria-label="障がい者雇用の求人サイト パラちゃんねるに無料新規登録する">
                <picture className="footer_register" aria-hidden="true">
                <source srcSet="/assets/img/register_btn.png" width="473" height="106" media="(min-width: 990px)"/>
                <img src="/assets/img/register_btn.png" alt="障がい者雇用の求人サイト パラちゃんねるに無料新規登録する" width="273" height="53" loading="lazy"/>
                </picture>
            </a>
            </div>

            <div className="footer_message-wrap">
            <p className="footer_message">
                <span className="message_block">働くことに障害のある当事者と一緒に作ったサービスです。</span>
                <span className="message_block">不採用通知で落ち込まずに活動できるよう</span>
                <span className="message_block">「10日以内に企業から回答がない」場合は</span>
                <span className="message_block">自動的に不合格する仕組みを入れるなど。</span>
                <span className="message_block">当事者の声を活かしています。ぜひとも、ご活用ください！</span>

                <span className="footer_copy">© parachannnel</span>
            </p>
            </div>
        </div>
        </footer>
        </div>
    </div>
  )
}

export default MatomeDetail




function About() {
  const pageTitle = '障がい者雇用専門の求人サイト「パラちゃんねる」とは｜パラちゃんねる';
  document.title = pageTitle;

    return (
        <main id="about">
    <section>
      <div className="mv">
        <h2><img src="/assets/img/about/mv.jpg" alt="みんな違うから面白い！キミの輝きをみつけよう！" className="wrapper"/></h2>
      </div>
      <div className="wrapper">
        <div className="cont1">
          <div className="white_box">
            <h3>パラちゃんねるとは</h3>
            <p>2020年1月に始まった多様性を推進するプロジェクト「パラちゃんねる」。<br/>
            私たちは、『誰もが特性を活かせる、多様な選択肢がある社会』を目指し、障がい者雇用を促進することで「働く」側面から社会課題の解決に挑戦しています。<br/>
            <br/>
            現在、障がい者雇用における課題は３つと考えています。<br/>
            <br/>
            １）企業の資金力や人材のスペックに制限された就職・転職市場が存在している。<br/>
            ２）障がい者雇用の法定雇用率に囚われた採用活動が継続されている。<br/>
            ３）職場内での多様性の推進が実施されていない。<br/>
            <br/>
            業界・企業・個人にある課題に対し、耳で聞くラジオ、目で見るコラム、自由な出会いを創出する求人サイトという3つの施策により障がい者雇用の活性化と共に定着率の向上に寄与します。</p>
            <div className="item_box">
              <h4><img src="/assets/img/about/logo01.svg" alt="耳で聞くラジオ パラちゃんねる"/></h4>
              <h5><img src="/assets/img/about/icon01.svg" alt=""/>パラちゃんねる<br className="sp_only"/>ラジオ</h5>
              <p>「知るを増やす」をテーマにパラアスリートや障がい・福祉の関連情報をラジオ・Youtube・Podcastで発信するラジオ番組です。</p>
              <img src="/assets/img/about/icon02.png" alt=""/>
            </div>
            <div className="item_box">
              <h4><img src="/assets/img/about/logo02.svg" alt="目で見るコラム パラちゃんねるカフェ"/></h4>
              <h5>パラちゃんねる<br className="sp_only"/>カフェ<img src="/assets/img/about/icon03.svg" alt=""/></h5>
              <p>「働く×障がい」をテーマに障がいや生きづらさのある当事者と障がい者雇用を促進する企業が生の声を配信するコラムサイトです。</p>
              <img src="/assets/img/about/icon04.png" alt=""/>
            </div>
            <div>
              <h4><img src="/assets/img/about/logo03.svg" alt="求人サイト パラちゃんねる"/></h4>
              <p>「自由な出会い」をテーマに誰もが使いやすく、<br className="sp_only"/>人と企業の出会いを創出する求人サイトです。<br/>
              求職者はもちろん、求人企業も<br className="sp_only"/><span>2023年12月まで無料</span>で利用できます。</p>
            </div>
            <img src="/assets/img/about/img.jpg" alt=""/>
          </div>
        </div>
        <div className="cont2">
          <h3>求人サイトについて</h3>
          <div className="white_box">
            <h4>人と企業が自らの意志で出会うことがベストマッチングにつながる。<br/>
            企業の求人掲載費用や求職者の経歴やスキルによる優劣は関係ない。</h4>
            <h5>■ 求職者にとって…</h5>
            <p>１．スキルだけでなく相性を重視したマッチング<br/>
            ２．学歴や職歴数ではなく長所や強みに焦点を当てたマイページ<br/>
            ３．簡単スケジュール調整と管理・通知機能</p>
            <h5>■ 求人企業にとって…</h5>
            <p>１．求人掲載・スカウトも完全無料<br/>
            ２．ハローワーク求人と連携、3分で簡単に求人作成<br/>
            ３．月間5万ＰＶのパラちゃんねるカフェを利用した求人宣伝</p>
          </div>
        </div>
        <div className="cont3">
          <h3>コラムサイトについて</h3>
          <div className="white_box">
            <h4>「障がい」という一括りや定義づけではなく、人を知ることに意味がある。<br/>
            当事者の経験や想いと一緒に働く仲間の想いを互いに知ろう、学ぼう。</h4>
            <h5>■ 障がいや生きづらさのある当事者にとって…</h5>
            <p>1．当事者のリアルな情報によるライフハックが見つかる<br/>
            2．障がい種別問わず、多様な視点をキャッチできる<br/>
            3．障がい者雇用に取り組む企業をチェックできる</p>
            <h5>■ 当事者と一緒に働く仲間にとって…</h5>
            <p>1．偏った偏見ではなく当事者のリアルな情報がキャッチできる<br/>
            2．障がい種別問わず、多様な視点をキャッチできる<br/>
            3．障がい者雇用の受け入れる準備ができる</p>
          </div>
        </div>
      </div>
    </section>
  </main>
       
    );
  }
  
  export default About;
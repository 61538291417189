import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { fetchAdminOpenJobRegionsRequest } from '../../../redax/actions/adminOpenJobRegionsActions/adminOpenJobRegionsActions';
import { fetchAdminCompanyAllListRequest } from '../../../redax/actions/adminComapnyAllListActions/adminComapnyAllListActions';
import { fetchPostCodeRequest } from '../../../redax/actions/postcodeActions/postcodeActions';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';
import { fetchAdminRecruitmentPostRequest } from '../../../redax/actions/adminRecruitmentPostActions/adminRecruitmentPostActions';
import { fetchAdminRecruitmentJobOfferRequest } from '../../../redax/actions/adminRecruitmentJobOfferActions/adminRecruitmentJobOfferActions';

const AdminApplicantsNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const loginData = useSelector((state: RootState) => state.login);
  const postcodeData = useSelector((state: RootState) => state.postcode);
  const adminOpenJobRegions = useSelector((state: RootState) => state.adminOpenJobRegions);
  const adminComapnyAllList = useSelector((state: RootState) => state.adminComapnyAllList);
  const adminRecruitmentPost = useSelector((state: RootState) => state.adminRecruitmentPost);
  const adminRecruitmentJobOffer = useSelector((state: RootState) => state.adminRecruitmentJobOffer);
  const [loadScreen, setLoadScreen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  let applicant: any = [];
  let educational_background = [];
  let education_background = [];
  const [tempolary, settempolary] = useState(false);
  const [family_name, setFamilyName] = useState<string | null>(null);
  const [first_name, setFirstName] = useState<string | null>(null);
  const [family_name_kana, setFamilyNameKana] = useState<string | null>(null);
  const [first_name_kana, setFirstNameKana] = useState<string | null>(null);
  const [postCode, setPostCode] = useState<string | null>(null);
  const [address, setAddress] = useState('');
  const [mobile_number, setMobileNumber] = useState('');
  const [prefectureId, setPrefectureId] = useState(String);
  const [prefectureName, setPrefectureName] = useState(String);
  const [applicantPrefectureName, setApplicantPrefectureName] = useState(String);
  const [cityId, setCityId] = useState(String);
  const [cityName, setCityName] = useState(String);
  const [applicantCityName, setApplicantCityName] = useState(String);
  const [face_photo, setFacePhoto] = useState<any>(null);
  const [face_photo1, setFacePhoto1] = useState<any>(null);
  const [isFinalEducationModal, setIsFinalEducationModal] = useState(false);
  const [isGraduationDateModal, setIsGraduationDateModal] = useState(false);
  const [isBirthdayOpen, setBirthdayOpen] = useState(false);
  const [isNewGraduate, setNewGraduate] = useState(false);
  const [familyName, setFamylyName] = useState(null);
  const [email, setEmail] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [authenticate, setAuthenticate] = useState(null);
  const [btn, setBtn] = useState(false);
  const [lostPost, setLoadPost] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [errorPostCode, setErrorPostCode] = useState(false);  
  const [errorFamilyName, setErrorFamilyName] = useState(false);
  const [errorFamilyNameKana, setErrorFamilyNameKana] = useState(false);
  const [errorFamilyNameMaxlength, setErrorFamilyNameMaxLength] = useState(false);
  const [errorFamilyNameKanaMaxlength, setErrorFamilyNameKanaMaxLength] = useState(false);
  const [errorFamilyNameKanaCheck, setErrorFamilyNameKanaCheck] = useState(false);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorFirstNameKana, setErrorFirstNameKana] = useState(false);
  const [errorFirstNameMaxlength, setErrorFirstNameMaxLength] = useState(false);
  const [errorFirstNameKanaMaxlength, setErrorFirstNameKanaMaxLength] = useState(false);
  const [errorFirstNameKanaCheck, setErrorFirstNameKanaCheck] = useState(false);
  const [errorPostcodeMinMaxLength, setErrorPostcodeMinMaxLength] = useState(false);
  const [errorMobileNumber, setErrorMobileNumber] = useState(false);
  const [postCodeNumeric, setPostCodeNumeric] = useState(false);
  const [postCodeMinLength, setPostCodeMinLength] = useState(false);
  const [postCodeMaxLength, setPostCodeMaxLength] = useState(false);
  const [mobileNumericError, setMobileNumericError] = useState(false);
  const [mobileMinLengthError, setMobileMinLengthError] = useState(false);
  const [mobileMaxLengthError, setMobileMaxLengthError] = useState(false);
  const [birthday, setBirthday] = useState('');
  const [finalEducationData, setFinalEducationData] = useState<any[]>([]); 
  const [selectedFinalEducationId, setSelectedFinalEducationId] = useState(null);
  const [jobCareerCountId, setJobCareerCountId] = useState<any>(null);
  const [selectedGraduationDate, setSelectedGraduationDate] = useState<any>(null);
  const [minYear, setMinYear] = useState<any>(null);
  const [maxYear, setMaxYear] = useState<any>(null);
  const [baseMonth, setBaseMonth] = useState<any>(null);
  const [baseMonthThanAgo, setBaseMonthThanAgo] = useState(false);
  const [onlyPast, setOnlyPast] = useState(false);
  const [enableNone, setEnableNone] = useState(false);
  const [gender, setGender] = useState(null); 
  const [classErr, setClassEr] = useState('');
  const [classErr2, setClassEr2] = useState('');
  const [selectedFinalEducationName, setSelectedFinalEducationName] = useState<any>(null);
  const [navigation_book, setNavigationBook] = useState(null); 
  const [edited, setEdited] = useState(false); 
  const [currentSlide, setCurrentSlide] = useState(0);
  let monthly = 13;    
  let monthEndDay: any;
  let dataSetNames = ['年', '月', '日'];    
  let currentSlideNumber = 0;
  let postCodeMaxLen = 7;
  let mobileMinLen = 10;
  let mobileMaxLen = 11;
  let finalEducationDataSetName = '最終学歴';
  let finalEducationTitle = '最終学歴';
  let graduationdataSetName="卒業年度"
  let graduationDataSetNames = ['年', '月']
  let graduationTitle = '卒業・卒業見込み年度';
  let applicantBirthYear: any;
  let applicantBirthMonth: any;
  let applicantBirthDay: any;
  // let BirthDay: any;
  const [years, setYears] = useState<any[]>([]);
  const [months, setMonths] = useState<any[]>([]); 
  const [days, setDays] = useState<any[]>([]); 
  const [filterYears, setFilterYears] = useState<any[]>([]);
  const [filterMonths, setFilterMonths] = useState<any[]>([]);
  const [year, setYear] = useState<any>(null);
  const [month, setMonth] = useState();
  const [graduationYear, setGraduationYear] = useState<any>();
  const [graduationMonth, setGraduationMonth] = useState<any>();
  const [day, setDay] = useState();
  const [birthModalSlide, setBirthModalSlide] = useState(0);
  const [graduationModalSlide, setGraduationModalSlide] = useState(0);
  const regions = useSelector((state: RootState) => state.regions);
  const putregion = useSelector((state: RootState) => state.putregion);
  const applicants   = useSelector((state: RootState) => state.applicants);    
  const { postcode } = useSelector((state: RootState) => state.postcode);
  const [firstLoad, setFirstLoad] = useState(false);
  const [nowage, setNowage] = useState('');
  

  function setImage() {
    const fileInput = fileInputRef.current;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const fileImg = fileInput.files[0];
      if (fileImg.type.startsWith('image/')) {
        const logoUrl = URL.createObjectURL(fileImg);
        const reader = new FileReader();
        reader.readAsDataURL(fileImg);
        reader.onload = function () {
            setFacePhoto(reader.result);
            setFacePhoto1(reader.result);
        };
      }
    }
  }

  const handleFamilyName = (event: any ) => {
    setEdited(true);
    setFamilyName(event.target.value);
  };

  const handleFirstName = (event: any) => {
    setEdited(true);
    setFirstName(event.target.value);
  };

  function onChangeFamilyNameKana(e: any) { 
    setEdited(true);
    const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');  
    setFamilyNameKana(e.target.value);
    if(e.target.value === '') {
        setErrorFamilyNameKana(true);
    } else {
        if(e.target.value.length > 100) {
            setErrorFamilyNameKanaMaxLength(true);
            setErrorFamilyNameKana(true);
        } else {
            setErrorFamilyNameKanaMaxLength(false);
            setErrorFamilyNameKana(false);
        }
        if(kanaValidate.test(e.target.value)) {
            setErrorFamilyNameKanaCheck(false);
            setErrorFamilyNameKana(false);
        } else {
            setErrorFamilyNameKanaCheck(true);
            setErrorFamilyNameKana(true);
        }
    }        
  }

  function onChangeFirstNameKana(e: any) {  
    setEdited(true);
    const kanaValidate = new RegExp('^([ァ-ン]|ー)+$'); 
    setFirstNameKana(e.target.value);     
    if(e.target.value === '') {
        setErrorFirstNameKana(true);
    } else {
        if(e.target.value.length > 100) {
            setErrorFirstNameKanaMaxLength(true);
            setErrorFirstNameKana(true);
        } else {
            setErrorFirstNameKanaMaxLength(false);
            setErrorFirstNameKana(false);
        }
        if(kanaValidate.test(e.target.value)) {
            setErrorFirstNameKanaCheck(false);
            setErrorFirstNameKana(false);
        } else {
            setErrorFirstNameKanaCheck(true);
            setErrorFirstNameKana(true);
        }
    }        
  }
  function onChangeBirthdayYear(e:any) {
    setYear(e.target.value);
  }
  function onChangeBirthdayMonth(e:any) {
    setMonth(e.target.value);
  }
  function onChangeBirthdayDay(e:any) {
    setDay(e.target.value);
  }
  useEffect(() => {
    console.log(year,month,day);
    if(year !== null && month !== undefined && day !== undefined){
        setBirthday(`${year}-${month}-${day}`)
        calcAge();
        setNowage( calcAge())
    } else {
        setNowage('')
    }
}, [year,month,day,nowage,birthday]);


function calcAge () {
    if (birthday) {
        const today = new Date();
        const b = birthday.split('-')
        const baseAge = today.getFullYear() - parseInt(b[0]);
        return baseAge+'歳';
    } else {
      return ''
    }
}

function chooseGender(e: any) {
    setEdited(true);
    setGender(e.target.value);
    console.log(e.target.value);
  }


  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader /> : null}
      <section id='openjob'>
        <div className='adminPageWrapper'>
          <div className="header">
            <h2 className="title">求職者編集</h2>
          </div>  
          <div className='body'>
            <div className="">
              <div className="textWrapper">
                <a onClick={() => navigate('/admin/openjob_list')} className='blueBtn'>＜ 一つ前に戻る</a>
              </div>
            </div>
            <div className='step1'>
                <div>
                    <h1>顔写真</h1>
                    <div className='bordered mb30'>
                        <div className='dFlex' style={{justifyContent:'center'}}>
                            <div className="photo">
                                <div className="img_box">
                                    <img src={face_photo1 == null ? '/assets/img/company/img02.jpg': face_photo1} alt=""/>
                                </div>
                                <p className="btn">
                                    <label className='imageBtn'><input style={{display:'none'}} type="file" className="btn " ref={fileInputRef} onChange={setImage}/>ファイルを選択 </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h1>プロフィール</h1>
                    <div className='bordered'>
                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>姓 <span style={{ color: 'red' }}>※必須</span></label>
                                <input placeholder='例)山田' type="text" name="name1" value={family_name || ''} onChange={(event) => handleFamilyName(event)}/>
                            </div>
                            <div>
                                <label>名 <span style={{ color: 'red' }}>※必須</span> </label>
                                <input placeholder='例)太郎' type="text" name="name2" value={first_name || ''} onChange={(event) => handleFirstName(event)}/>
                            </div>
                        </div>

                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>性(フリガナ) <span style={{ color: 'red' }}>※必須</span></label>
                                <input placeholder='例)ヤマダ' type="text" name="kana1" className={errorFamilyNameKana ? "error" : ""} value={family_name_kana || ''} onChange={(e) => onChangeFamilyNameKana(e)}/>
                            </div>
                            <div>
                                <label>名(フリガナ) <span style={{ color: 'red' }}>※必須</span> </label>
                                <input placeholder='例)タロウ' type="text" name="kana2" className={errorFirstNameKana ? "error" : ""} value={first_name_kana || ''} onChange={(e) => onChangeFirstNameKana(e)}/>
                            </div>
                        </div>
                        <div className='dFlex'>
                            <div style={{marginRight: '5px'}}>
                                <label>年 (フリガナ) <span style={{color: 'red'}}>※必須</span></label>
                                <input placeholder='入力してください' type="text" name="year" value={year || ''} onChange={(e) => onChangeBirthdayYear(e)}/>
                            </div>
                            <p style={{margin: '10px 5px'}}>年</p>
                            <div style={{marginRight: '5px'}}>
                                <label>月 <span style={{color: 'red'}}>※必須</span></label>
                                <select value={month || ''} onChange={(e) => onChangeBirthdayMonth(e)}>
                                    <option value="" disabled>選択してください</option>
                                    {[...Array(12)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <p style={{margin: '10px 5px'}}>月</p>
                            <div style={{marginRight: '5px'}}>
                                <label>日 <span style={{color: 'red'}}>※必須</span></label>
                                <select value={day || ''} onChange={(e) => onChangeBirthdayDay(e)}>
                                    <option value="" disabled>選択してください</option>
                                    {[...Array(31)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <p style={{margin: '10px 5px'}}>日</p>
                        </div>
                        <div>
                            <label>年齢</label>
                            <input className='wid300' type="text" name="nowage" disabled value={nowage || ''}/>
                        </div>
                        <div>
                        <select value={gender || ''} onChange={chooseGender}>
                            <option value={1}>男</option>
                            <option value={2}>女</option>
                            <option value={3}>回答しない</option>
                        </select>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>住所</h1>
                    <div className='bordered'>

                    </div>
                </div>
            </div>
           
            <div style={{display:'flex',position:'relative', alignItems:'center', justifyContent:'center',marginTop:'20px'}}>
                <button className='createBtn' style={{padding:'5px 100px', fontSize:'18px'}}>作成する</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminApplicantsNew;
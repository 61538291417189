import { useEffect, useState } from "react";

function Footer() {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  function returnTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  if(window.location.href.includes('recruiter_lp')) {
    return (
      <footer className="lp_footer">
        {visible ? (
          <a href="javascript:void(0)" onClick={returnTop} className="top_btn" ><img src="/assets/img/lp/top_btn.svg" alt="TOPへ戻る"/></a>
        ) : null}
          <div className="cont2">
            <div className="wrapper">
              <div>
                <p>&copy; 2009 - {new Date().getFullYear()} parachannnel</p>
              </div>
            </div>
          </div>
      </footer>
    );
  } else {
    return (
      <footer>
      <div className="cont1">
        <div className="wrapper">
          <p>
            <img
              src="/assets/img/footer_text.svg"
              alt="PARA CHANNEL is always be by your side."
            />
          </p>
          <div>
            {/* <div className="category_box">
              <h2>お仕事探し</h2>
              <ul>
                <li><a href="/recruitments/?page=1&employment_status_ids[]=1">正社員で探す</a></li>
                <li><a href="/recruitments/?page=1&job_category_type_ids[]=1&job_category_ids[]=1">事務系のお仕事</a></li>
                <li><a href="/recruitments/?page=1&annual_income_id=7">年収の高いお仕事</a></li>
              </ul>
            </div> */}
          </div>
          <ul className="link_box">
            <li><a href="https://www.parachannel.jp/column/" target="_blank"><img src="/assets/img/banner.jpg" alt="パラちゃんねるカフェ" /></a></li>
            <li className="sns"><a href="https://twitter.com/CareerArt_" target="_blank"><img src="/assets/img/sns_icon02.png" alt="Twitter" /></a></li>
          </ul>
        </div>
      </div>
      <div className="cont2">
        <div className="wrapper">
          <div>
            <nav>
              <ul>
                <li><a href="/terms1/">利用規約（求職者の方向け）</a></li>
                <li><a href="/terms2/">利用規約（企業の採用担当者様向け）</a></li>
                <li><a href="/recruiter_lp/">企業の採用担当者様へ</a></li>
                <li><a href="/privacy/">プライバシーポリシー</a></li>
                <li><a href="/contact/">お問い合わせ</a></li>
              </ul>
            </nav>
            <p>&copy; 2009 - {new Date().getFullYear()} parachannnel</p>
          </div>
        </div>
      </div>
    </footer>
    );
  }
}

export default Footer;
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function MypageChart(props: { rate: any; }) {
    let rate: any = [];
    if(props) {
        rate  = props.rate;
    }
    const data = {
        datasets: [
            {
            backgroundColor: [
                '#00a0e9',
                '#dbffff'
            ],
            data: [rate, (100 - rate)],
            borderWidth: 0,
            },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout:"70%",
    };
    return <Doughnut data={data} options={options}/>
}
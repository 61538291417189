import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { masterInfoTypes } from "../../ActionTypes/masterInfoTypes";
import { fetchMasterInfoFailure, fetchMasterInfoSuccess } from "../../actions/masterInfoActions/masterInfoActions";
var getMasterInfo = (param: any) => axios.get(API_BASE_ADDRESS + '/master?' + param, {headers: { Accept: 'application/json', Prefer: 'example=example-1'}});

function* fetchMasterInfoSaga(param: any) {
  console.log(param)
  try {
    const response: AxiosResponse = yield call(getMasterInfo, param.param);
    yield put(
      fetchMasterInfoSuccess({
        masterInfo: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchMasterInfoFailure({
        error: e.message
      })
    );
  }
}

function* MasterInfoSaga() {
  yield takeLatest(masterInfoTypes.FETCH_MASTER_INFO_REQUEST, fetchMasterInfoSaga);
}

export default MasterInfoSaga;

import { useEffect, useState } from 'react';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import Loader from '../../loader/loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchAdminScoutsDetailRequest } from '../../../redax/actions/adminScoutsDetailActions/adminScoutsDetailActions';
const AdminScoutListDetail = () => {
  const [loadScreen, setLoadScreen] = useState(true);
  const navigate = useNavigate();
  const adminScoutsDetail = useSelector((state: RootState) => state.adminScoutsDetail);
  const dispatch = useDispatch();
  const [scouts, setScouts] = useState<any>([]);
  const { id } = useParams();
  console.log(id);


  useEffect(() => {
      dispatch(fetchAdminScoutsDetailRequest(id));
}, [dispatch]); 

  useEffect(() => {
    if (adminScoutsDetail.pending) {
        setLoadScreen(false)
        setScouts(adminScoutsDetail.scout);
    }
  }, [adminScoutsDetail]);

  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section id='content'>
        <div className='body'>
        
          <div className="cont_boxAdmin">
            <div className="">
                <div className="textWrapper">
                  <a  onClick={() => navigate('/admin/scout_list')} className='blueBtn'>＜ 一覧に戻る</a>
                </div>
            </div>
                {scouts ? 
                <dl className="nest1">
                  <dt>ID</dt>
                  <dd>{scouts.id}</dd>
                  <dt>日時</dt>
                  <dd>{scouts.created_at}</dd>
                  <dt>企業</dt>
                  <dd>{scouts?.company?.name}</dd>
                  <dt>求人</dt>
                  <dd>
                      <ul>
                      {scouts.recruitments && scouts.recruitments.map((recruitment:any, index:any) => (
                          <li key={index}>{recruitment}</li>
                      ))}
                      </ul>
                  </dd>
                  <dt>送付</dt>
                  <dd>{scouts.sent_count}</dd>
                  <dt>開封</dt>
                  <dd>{scouts.opened_count}</dd>
                  <dt>メッセージ</dt>
                  <dd> { scouts.content } </dd>
                </dl>
                : <></>}
                  
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminScoutListDetail;

import { combineReducers } from "redux";
import recruitmentsReduser from "./recruitmentsReduser/recruitmentsReduser";
import regionsReduser from "./regionsReduser/regionsReduser";
import articlesReduser from "./articlesReduser/articlesReduser";
import recruitmentsListReduser from "./recruitmentsListReduser/recruitmentsListReduser";
import recruitmentsSearchReduser from "./recruitmentsSearchReduser/recruitmentsSearchReduser";
import recruitmentsDetailReduser from "./recruitmentsDetailReduser/recruitmentsDetailReduser";
import columnFetchListReduser from "./columnFetchListReduser/columnFetchListReduser";
import loginReduser from "./loginReduser/loginReduser";
import applicantReduser from "./applicantReduser/applicantReduser";
import companiesReduser from "./companiesReduser/companiesReduser";
import recruitmentsPublicListReduser from "./recruitmentsPublicListReduser/recruitmentsPublicListReduser";
import applicantsReduser from "./applicantsReduser/applicantsReduser";
import applicationsReduser from "./applicationsReduser/applicationsReduser";
import messagesReduser from "./messagesReduser/messagesReduser";
import interviewsReduser from "./interviewsReduser/interviewsReduser";
import postcodeReduser from "./postcodeReduser/postcodeReduser";
import messagesPostReduser from "./messagesPostReduser/messagesPostReduser";
import changeUpdateStatusReduser from "./changeUpdateStatusReduser/changeUpdateStatusReduser";
import emailChangeReduser from "./emailChangeReduser/emailChangeReduser";
import topRecruiterReduser from "./topRecruiterReduser/topRecruiterReduser";
import passwordResetReduser from "./passwordResetReduser/passwordResetReduser";
import resetReduser from "./resetReduser/resetReduser";
import signupReduser from "./signupReduser/signupReduser";
import articlesRecruiterReduser from "./articlesRecruiterReduser/articlesRecruiterReduser";
import changeUpdateApplicantsReduser from "./changeUpdateApplicantsReduser/changeUpdateApplicantsReduser";
import downloadCareerReduser from "./downloadCareerReduser/downloadCareerReduser";
import downloadResumeReduser from "./downloadResumeReduser/downloadResumeReduser";
import articlesImagesReduser from "./articlesImagesReduser/articlesImagesReduser";
import recruitmentsCompanyReduser from "./recruitmentsCompanyReduser/recruitmentsCompanyReduser";
import previewReduser from "./previewReduser/previewReduser";
import stepReduser from "./stepReduser/stepReduser";
import columnsReduser from "./columnsReduser/columnsReduser";
import columnFetchItemReduser from "./columnFetchItemReduser/columnFetchItemReduser";
import countReduser from "./countReduser/countReduser";
import putRegionReduser from "./putRegionReduser/putRegionReduser";
import scoutsApplicantsReduser from "./scoutsApplicantsReduser/scoutsApplicantsReduser";
import recruitmentsApplicationReduser from "./recruitmentsApplicationReduser/recruitmentsApplicationReduser";
import applicationCompanyReduser from "./applicationCompanyReduser/applicationCompanyReduser";
import invitationReduser from "./invitationReduser/invitationReduser";
import initReduser from "./initReduser/initReduser";
import downloadApplicationCsvReduser from "./downloadApplicationCsvReduser/downloadApplicationCsvReduser";
import articlesRecruiterDetailReduser from "./articlesRecruiterDetailReduser/articlesRecruiterDetailReduser";
import recruitmentsCopyReduser from "./recruitmentsCopyReduser/recruitmentsCopyReduser";
import recruiterRecruitmentsDetailReduser from "./recruiterRecruitmentsDetailReduser/recruiterRecruitmentsDetailReduser";
import recruiterPricingReduser from "./recruiterPricingReduser/recruiterPricingReduser";
import articlesSubmitReduser from "./articlesSubmitReduser/articlesSubmitReduser";
import articlesDeleteReduser from "./articlesDeleteReduser/articlesDeleteReduser";
import articlesCreateReduser from "./articlesCreateReduser/articlesCreateReduser";
import scoutsRecruitmentReduser from "./scoutsRecruitmentReduser/scoutsRecruitmentReduser";
import scoutsReduser from "./scoutsReduser/scoutsReduser";
import scoutsCompanyReduser from "./scoutsCompanyReduser/scoutsCompanyReduser";
import articlesImageReduser from "./articlesImageReduser/articlesImageReduser";
import savedConditionsDeleteReduser from "./savedConditionsDeleteReduser/savedConditionsDeleteReduser";
import savedConditionsReduser from "./savedConditionsReduser/savedConditionsReduser";
import savedConditionsCreateReduser from "./savedConditionsCreateReduser/savedConditionsCreateReduser";
import recruitersReduser from "./recruitersReduser/recruitersReduser";
import settingsReduser from "./settingsReduser/settingsReduser";
import handicapsReduser from "./handicapsReduser/handicapsReduser";
import favCountReduser from "./favCountReduser/favCountReduser";
import recruitmentsStepTwoReduser from "./recruitmentsStepTwoReduser/recruitmentsStepTwoReduser";
import scoutsCandidateReduser from "./scoutsCandidateReduser/scoutsCandidateReduser";
import scoutsSavedConditionsReduser from "./scoutsSavedConditionsReduser/scoutsSavedConditionsReduser";
import applicationCreateReduser from "./applicationCreateReduser/applicationCreateReduser";
import recruitmentsDeleteReduser from "./recruitmentsDeleteReduser/recruitmentsDeleteReduser";
import recruitmentsStatusChangeReduser from "./recruitmentsStatusChangeReduser/recruitmentsStatusChangeReduser";
import scoutsEntryReduser from "./scoutsEntryReduser/scoutsEntryReduser";
import applicantsPrReduser from "./applicantsPrReduser/applicantsPrReduser";
import applicantDecideReduser from "./applicantDecideReduser/applicantDecideReduser";
import helloReduser from "./helloReduser/helloReduser";
import openAiReduser from "./openAiReduser/openAiReduser";
import scoutsAlertReduser from "./scoutsAlertReduser/scoutsAlertReduser";
import adminScoutMatchingJobsReduser from "./adminScoutMatchingJobsReduser/adminScoutMatchingJobsReduser";
import adminScoutApplicantsReducer from "./adminScoutsApplicantsReducer/adminScoutApplicantsReducer";
import openGoogleAiReduser from "./openGoogleAiReduser/openGoogleAiReduser";
import recruiterPlanReduser from "./recruiterPlanReduser/recruiterPlanReduser";
import recruiterPaymentlistReduser from "./recruiterInvoiceReduser/recruiterPaymentlistReduser";
import recruiterPaymentDetailReduser from "./recruiterInvoiceDetailReduser/recruiterPaymentDetailReduser";
import recruiterPlanPostReduser from "./recruiterPlanPostReduser/recruiterPlanPostReduser";
import recruitmentsJobsReduser from "./recruitmentsJobsReduser/recruitmentsJobsReduser";
import adminInvoiceReduser from "./adminInvoiceReduser/adminInvoiceReduser";
import adminInvoicePostReduser from "./adminInvoicePostReduser/adminInvoicePostReduser";
import adminCompanyListReduser from "./adminCompanyListReduser/adminCompanyListReduser";
import adminCompanyDetailReduser from "./adminCompanyDetailReduser/adminCompanyDetailReduser";
import masterInfoReduser from "./masterInfoReduser/masterInfoReduser";
import recruiterReceiptPostReduser from "./recruiterReceiptPostReduser/recruiterReceiptPostReduser";
import emailChangeTokenReduser from "./emailChangeTokenReduser/emailChangeTokenReduser";
import adminRecruitmentListReduser from "./adminRecruitmentListReduser/adminRecruitmentListReduser";
import adminRecruitmentCsvReduser from "./adminRecruitmentCsvReduser/adminRecruitmentCsvReduser";
import adminApplicationsListReduser from "./adminApplicationsListReduser/adminApplicationsListReduser";
import adminNewsListReduser from "./adminNewsListReduser/adminNewsListReduser";
import adminScoutsCompanyReduser from "./adminScoutsCompanyReduser/adminScoutsCompanyReduser";
import adminScoutsReduser from "./adminScoutsReduser/adminScoutsReduser";
import adminAdministratorsReduser from "./adminAdministratorsReduser/adminAdministratorsReduser";
import adminAdministratorsDeleteReduser from "./adminAdministratorsDeleteReduser/adminAdministratorsDeleteReduser";
import adminApplicantsListReduser from "./adminApplicantsListReduser/adminApplicantsListReduser";
import adminApplicantsCsvReduser from "./adminApplicantsCsvReduser/adminApplicantsCsvReduser";
import adminAdministratorsRegionsReduser from "./adminAdministratorsRegionsReduser/adminAdministratorsRegionsReduser";
import adminScoutsDetailReduser from "./adminScoutsDetailReduser/adminScoutsDetailReduser";
import adminNewsNewReduser from "./adminNewsNewReduser/adminNewsNewReduser";
import adminOpenJobRegionsReduser from "./adminOpenJobRegionsReduser/adminOpenJobRegionsReduser";
import adminCompanyAllListReduser from "./adminCompanyAllListReduser/adminCompanyAllListReduser";
import adminRecruitmentPostReduser from "./adminRecruitmentPostReduser/adminRecruitmentPostReduser";
import adminRecruitmentDetailReduser from "./adminRecruitmentDetailReduser/adminRecruitmentDetailReduser";
import adminRecruitmentEditReduser from "./adminRecruitmentEditReduser/adminRecruitmentEditReduser";
import adminRecruitmentCopyReduser from "./adminRecruitmentCopyReduser/adminRecruitmentCopyReduser";
import adminRecruitmentJobOfferReduser from "./adminRecruitmentJobOfferReduser/adminRecruitmentJobOfferReduser";

const rootReducer: any = combineReducers({
  recruitments: recruitmentsReduser,
  recruitmentsList: recruitmentsListReduser,
  recruitmentsSearch: recruitmentsSearchReduser,
  recruitmentsDetail: recruitmentsDetailReduser,
  recruitmentsApplication: recruitmentsApplicationReduser,
  regions: regionsReduser,
  login: loginReduser,
  articles: articlesReduser,
  columnList: columnFetchListReduser,
  columnItem: columnFetchItemReduser,
  count: countReduser,
  applicant: applicantReduser,
  companies: companiesReduser,
  recruitmentsPublicList: recruitmentsPublicListReduser,
  applicants: applicantsReduser,
  applications: applicationsReduser,
  messages: messagesReduser,
  interviews: interviewsReduser,
  postcode: postcodeReduser,
  messagespost: messagesPostReduser,
  changeupdatestatus: changeUpdateStatusReduser,
  emailChange: emailChangeReduser,
  emailChangeToken: emailChangeTokenReduser,
  topRecruiter: topRecruiterReduser,
  passwordReset: passwordResetReduser,
  reset: resetReduser,
  signup: signupReduser,
  articlesRecruiter: articlesRecruiterReduser,
  changeupdateapplicants: changeUpdateApplicantsReduser,
  downloadCareer: downloadCareerReduser,
  downloadResume: downloadResumeReduser,
  articlesImages: articlesImagesReduser,
  recruitmentsCompany: recruitmentsCompanyReduser,
  preview: previewReduser,
  step: stepReduser,
  columns: columnsReduser,
  putregion: putRegionReduser,
  scoutsApplicants: scoutsApplicantsReduser,
  applicationCompany: applicationCompanyReduser,
  invitation: invitationReduser,
  init: initReduser,
  applicationCsvData: downloadApplicationCsvReduser,
  articlesRecruiterDetail: articlesRecruiterDetailReduser,
  recruitmentsCopy: recruitmentsCopyReduser,
  recruiterRecruitmentsDetail: recruiterRecruitmentsDetailReduser,
  recruiterPricing: recruiterPricingReduser,
  articlesSubmit: articlesSubmitReduser,
  articlesDelete: articlesDeleteReduser,
  articlesCreate: articlesCreateReduser,
  scoutsRecruitment: scoutsRecruitmentReduser,
  scouts: scoutsReduser,
  scoutsCompany: scoutsCompanyReduser,
  articlesImage: articlesImageReduser,
  savedConditions: savedConditionsReduser,
  savedConditionsDelete: savedConditionsDeleteReduser,
  savedConditionsCreate: savedConditionsCreateReduser,
  recruiters: recruitersReduser,
  settings: settingsReduser,
  handicaps: handicapsReduser,
  favCount: favCountReduser,
  recruitmentsStepTwo: recruitmentsStepTwoReduser,
  scoutsCandidate: scoutsCandidateReduser,
  scoutsSavedConditions: scoutsSavedConditionsReduser,
  applicationsCreate: applicationCreateReduser,
  recruitmentsDelete: recruitmentsDeleteReduser,
  recruitmentsStatusChange: recruitmentsStatusChangeReduser,
  entry: scoutsEntryReduser,
  pr: applicantsPrReduser,
  decide: applicantDecideReduser,
  hello: helloReduser,
  openAi: openAiReduser,
  scoutAlert: scoutsAlertReduser,
  jobs:adminScoutMatchingJobsReduser,
  adminApplicants:adminScoutApplicantsReducer,
  historyGoogle:openGoogleAiReduser,
  recruiterPlan:recruiterPlanReduser,
  recruiterInvoice:recruiterPaymentlistReduser,
  recruiterInvoiceDetail:recruiterPaymentDetailReduser,
  recruiterPlanPost:recruiterPlanPostReduser,
  recruitermentsJobs:recruitmentsJobsReduser,
  adminInvoice: adminInvoiceReduser,
  adminCompanyList: adminCompanyListReduser,
  adminRecruitmentList: adminRecruitmentListReduser,
  adminRecruitmentPost: adminRecruitmentPostReduser,
  adminRecruitmentDetail: adminRecruitmentDetailReduser,
  adminRecruitmentJobOffer: adminRecruitmentJobOfferReduser,
  adminRecruitmentCopy: adminRecruitmentCopyReduser,
  adminRecruitmentEdit: adminRecruitmentEditReduser,
  adminApplicantsList: adminApplicantsListReduser,
  adminApplicationsList: adminApplicationsListReduser,
  adminScouts: adminScoutsReduser,
  adminScoutsDetail: adminScoutsDetailReduser,
  adminScoutsCompany: adminScoutsCompanyReduser,
  adminAdministrators: adminAdministratorsReduser,
  adminAdministratorsDelete: adminAdministratorsDeleteReduser,
  adminAdministratorsRegions: adminAdministratorsRegionsReduser,
  adminOpenJobRegions: adminOpenJobRegionsReduser,
  adminComapnyAllList: adminCompanyAllListReduser,
  adminNewsList: adminNewsListReduser,
  adminNewsNew: adminNewsNewReduser,
  adminRecruitmentCsv: adminRecruitmentCsvReduser,
  adminApplicantsCsv: adminApplicantsCsvReduser,
  adminCompanyDetail: adminCompanyDetailReduser,
  adminInvoicePost: adminInvoicePostReduser,
  masterInfo: masterInfoReduser,
  receiptInfo: recruiterReceiptPostReduser,
});

export type RootState  = ReturnType<typeof rootReducer>;

export default rootReducer;
